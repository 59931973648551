

#art-main
{
   background: #FFFFFF;
   margin:0 auto;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   position: relative;
   width: 100%;
   min-height: 100%;
   left: 0;
   top: 0;
   cursor:default;
   overflow:hidden;
}
table, ul.art-hmenu
{
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
}

h1, h2, h3, h4, h5, h6, p, a, ul, ol, li
{
   margin: 0;
   padding: 0;
}
@font-face {
    font-family: "ArialNarrow";
    src: url("fonts/ARIALN.eot") format("embedded-opentype");
    }

@font-face {

    font-family: "ArialNarrow";
    src: url("fonts/ARIALN.ttf") format("truetype"),
         url("fonts/ARIALN.woff") format("woff"),
         url("fonts/ARIALN.svg") format("svg");
    }

/* Reset buttons border. It's important for input and button tags. 
 * border-collapse should be separate for shadow in IE. 
 */
.art-button
{
   border: 0;
   border-collapse: separate;
   -webkit-background-origin: border !important;
   -moz-background-origin: border !important;
   background-origin: border-box !important;
   background: #A1ADBA;
   -webkit-border-radius:3px;
   -moz-border-radius:3px;
   border-radius:3px;
   border-width: 0;
   padding:0 21px;
   margin:0 auto;
   height:24px;
}
hr {
  display: none;
}

.art-postcontent,
.art-postheadericons,
.art-postfootericons,
.art-blockcontent,
ul.art-vmenu a 
{
   text-align: left;
}

.art-postcontent,
.art-postcontent li,
.art-postcontent table,
.art-postcontent a,
.art-postcontent a:link,
.art-postcontent a:visited,
.art-postcontent a.visited,
.art-postcontent a:hover,
.art-postcontent a.hovered
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   line-height: 175%;
}

.art-postcontent p
{
   margin: 13px 0;
}

.art-postcontent h1, .art-postcontent h1 a, .art-postcontent h1 a:link, .art-postcontent h1 a:visited, .art-postcontent h1 a:hover,
.art-postcontent h2, .art-postcontent h2 a, .art-postcontent h2 a:link, .art-postcontent h2 a:visited, .art-postcontent h2 a:hover,
.art-postcontent h3, .art-postcontent h3 a, .art-postcontent h3 a:link, .art-postcontent h3 a:visited, .art-postcontent h3 a:hover,
.art-postcontent h4, .art-postcontent h4 a, .art-postcontent h4 a:link, .art-postcontent h4 a:visited, .art-postcontent h4 a:hover,
.art-postcontent h5, .art-postcontent h5 a, .art-postcontent h5 a:link, .art-postcontent h5 a:visited, .art-postcontent h5 a:hover,
.art-postcontent h6, .art-postcontent h6 a, .art-postcontent h6 a:link, .art-postcontent h6 a:visited, .art-postcontent h6 a:hover,
.art-blockheader .t, .art-blockheader .t a, .art-blockheader .t a:link, .art-blockheader .t a:visited, .art-blockheader .t a:hover,
.art-vmenublockheader .t, .art-vmenublockheader .t a, .art-vmenublockheader .t a:link, .art-vmenublockheader .t a:visited, .art-vmenublockheader .t a:hover,
.art-headline, .art-headline a, .art-headline a:link, .art-headline a:visited, .art-headline a:hover,
.art-slogan, .art-slogan a, .art-slogan a:link, .art-slogan a:visited, .art-slogan a:hover,
.art-postheader, .art-postheader a, .art-postheader a:link, .art-postheader a:visited, .art-postheader a:hover
{
   font-size: 22px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   line-height: 120%;
}

.art-postcontent a, .art-postcontent a:link
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
   color: #FA5705;
}

.art-postcontent a:visited, .art-postcontent a.visited
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
   color: #839BB4;
}

.art-postcontent  a:hover, .art-postcontent a.hover
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: underline;
   color: #FB7C3C;
}

.art-postcontent h1
{
   color: #6C7F93;
   margin: 10px 0 0;
   font-size: 26px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-blockcontent h1
{
   margin: 10px 0 0;
   font-size: 26px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h1 a, .art-postcontent h1 a:link, .art-postcontent h1 a:hover, .art-postcontent h1 a:visited, .art-blockcontent h1 a, .art-blockcontent h1 a:link, .art-blockcontent h1 a:hover, .art-blockcontent h1 a:visited 
{
   font-size: 26px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h2
{
   color: #8D9CAA;
   margin: 10px 0 0;
   font-size: 22px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-blockcontent h2
{
   margin: 10px 0 0;
   font-size: 22px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h2 a, .art-postcontent h2 a:link, .art-postcontent h2 a:hover, .art-postcontent h2 a:visited, .art-blockcontent h2 a, .art-blockcontent h2 a:link, .art-blockcontent h2 a:hover, .art-blockcontent h2 a:visited 
{
   font-size: 22px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h3
{
   color: #FB7C3C;
   margin: 10px 0 0;
   font-size: 20px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-blockcontent h3
{
   margin: 10px 0 0;
   font-size: 20px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h3 a, .art-postcontent h3 a:link, .art-postcontent h3 a:hover, .art-postcontent h3 a:visited, .art-blockcontent h3 a, .art-blockcontent h3 a:link, .art-blockcontent h3 a:hover, .art-blockcontent h3 a:visited 
{
   font-size: 20px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h4
{
   color: #4E6883;
   margin: 10px 0 0;
   font-size: 18px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-blockcontent h4
{
   margin: 10px 0 0;
   font-size: 18px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h4 a, .art-postcontent h4 a:link, .art-postcontent h4 a:hover, .art-postcontent h4 a:visited, .art-blockcontent h4 a, .art-blockcontent h4 a:link, .art-blockcontent h4 a:hover, .art-blockcontent h4 a:visited 
{
   font-size: 18px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h5
{
   color: #4E6883;
   margin: 10px 0 0;
   font-size: 15px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-blockcontent h5
{
   margin: 10px 0 0;
   font-size: 15px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h5 a, .art-postcontent h5 a:link, .art-postcontent h5 a:hover, .art-postcontent h5 a:visited, .art-blockcontent h5 a, .art-blockcontent h5 a:link, .art-blockcontent h5 a:hover, .art-blockcontent h5 a:visited 
{
   font-size: 15px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h6
{
   color: #A6B7C9;
   margin: 10px 0 0;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-blockcontent h6
{
   margin: 10px 0 0;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-postcontent h6 a, .art-postcontent h6 a:link, .art-postcontent h6 a:hover, .art-postcontent h6 a:visited, .art-blockcontent h6 a, .art-blockcontent h6 a:link, .art-blockcontent h6 a:hover, .art-blockcontent h6 a:visited 
{
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

header, footer, article, nav, #art-hmenu-bg, .art-sheet, .art-hmenu a, .art-vmenu a, .art-slidenavigator > a, .art-checkbox:before, .art-radiobutton:before
{
   -webkit-background-origin: border !important;
   -moz-background-origin: border !important;
   background-origin: border-box !important;
}

header, footer, article, nav, #art-hmenu-bg, .art-sheet, .art-slidenavigator > a, .art-checkbox:before, .art-radiobutton:before
{
   display: block;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

ul
{
   list-style-type: none;
}

ol
{
   list-style-position: inside;
}

html, body
{
   height: 100%;
}

body
{
   padding: 0;
   margin:0;
   min-width: 320px;
   color: #202020;
}

.art-header:before, #art-header-bg:before, .art-layout-cell:before, .art-layout-wrapper:before, .art-footer:before, .art-nav:before, #art-hmenu-bg:before, .art-sheet:before
{
   width: 100%;
   content: " ";
   display: table;
}
.art-header:after, #art-header-bg:after, .art-layout-cell:after, .art-layout-wrapper:after, .art-footer:after, .art-nav:after, #art-hmenu-bg:after, .art-sheet:after,
.cleared, .clearfix:after {
   clear: both;
   /*  font: 0/0 serif;  */
   font-size: 0px;
   line-height: 0px;
   font-family: serif;
   display: block;
   content: " ";
}

form
{
   padding: 0 !important;
   margin: 0 !important;
}

table.position
{
   position: relative;
   width: 100%;
   table-layout: fixed;
}

li h1, .art-postcontent li h1, .art-blockcontent li h1 
{
   margin:1px;
} 
li h2, .art-postcontent li h2, .art-blockcontent li h2 
{
   margin:1px;
} 
li h3, .art-postcontent li h3, .art-blockcontent li h3 
{
   margin:1px;
} 
li h4, .art-postcontent li h4, .art-blockcontent li h4 
{
   margin:1px;
} 
li h5, .art-postcontent li h5, .art-blockcontent li h5 
{
   margin:1px;
} 
li h6, .art-postcontent li h6, .art-blockcontent li h6 
{
   margin:1px;
} 
li p, .art-postcontent li p, .art-blockcontent li p 
{
   margin:1px;
}


.art-shapes
{
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   overflow: hidden;
   z-index: 0;
       width: 100%;
}

.art-slider-inner {
   position: relative;
   overflow: hidden;
   width: 100%;
   height: 100%;
}

.art-slidenavigator > a {
   display: inline-block;
   vertical-align: middle;
   outline-style: none;
   font-size: 1px;
}

.art-slidenavigator > a:last-child {
   margin-right: 0 !important;
}

/*верхнее меню*/
/*
fix: 21
.top-nav{
    background-color: #373737;
    width: 100%;
    height: 44px;
    text-align: center;
}
*/
.top-menu{
    /*  margin-top: 15px;*/
    display: inline-block;
    min-width: 320px;
    max-width: 1200px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}
.text-welcome{
margin-top: 15px;
font-family: Arial;
color: #ffffff;
font-size: 14px;
font-weight: 700;
line-height: 14px;
text-align: left;
float: left;
position: relative;
    display: inline-block;
}

 
.arrow-h{
	background-image: url('images/arrow-h.png');
  background-repeat: no-repeat;
	width: 129px;
  height: 28px;
  position: absolute;
  top: 16px;
  left: 186px;
}


.phone{
  margin-top: 10px;
  float: left;
  margin-left: 95px;
}
.phone img
{
    display: block;
    margin-right: 16px;
    position: absolute;
    top: 10px;
    width: 21px;
height: 20px;
}
.phone p{
  float: left;
  margin-left: 30px;
  font-family: Arial;
color: #ffffff;
font-size: 17px;
font-weight: 700;
letter-spacing: 0.425px;
text-align: right;
}
.phone p span .font16{
  font-family: Arial;
font-size: 16px;
letter-spacing: 0.4px;
text-align: right;
}
.phone p span .orang{
  font-family: Arial;
color:  #fe7900;
font-size: 16px;
letter-spacing: 0.4px;
text-align: right;
}

.coll-beck a{
margin-top: 15px;
font-family: Arial;
color: #fe7900;
font-size: 12px;
font-weight: 700;
letter-spacing: 0.36px;
text-decoration: underline;
text-align: left;
float: left;
  margin-left: 85px;
}
.coll-beck a:hover{
	color: #fff;

}
.our-projects{
  float: left;
  margin-left: 47px;
}
.project{
margin-top: 5.5px;
margin-left: 8px;
border-radius: 4px;
background-color:  #ffffff;
width: 91px;
height: 33px;
float: left;
position: relative;
}
.project:hover:before{
	background-image: url('images/project-hover-left.png');
	content: '';
	display: block;
	position: absolute;
	    top: 12px;
    left: -2px;
	width: 5px;
	height: 8px;
	background-repeat: no-repeat;
}
.project:hover:after{
	background-image: url('images/project-hover-right.png');
	content: '';
	display: block;
	position: absolute;
	top: 12px;
    left: 89px;

	width: 5px;
	height: 8px;
	background-repeat: no-repeat;
}
.ft img{
  margin-top: 2px;
}
.jm img{
  margin-top: 9.5px;
}
/*верхнее меню*/

/*Шапка*/
.art-header
{
   margin:10px auto 0;
   height: 120px;
   position: relative;
   min-width: 320px;
   max-width: 1200px;
   width: 100%;
   z-index: auto !important;
}
.logo
{  
  float: left;
  top: 0px;
  z-index: 102;
  width: 25%;
  height: 111px;
  display: block;
}.art-object227400126 h1, 
.art-object227400126 h2, 
.art-object227400126 h3, 
.art-object227400126 h4, 
.art-object227400126 h5, 
.art-object227400126 h6, 
.art-object227400126 p, 
.art-object227400126 a, 
.art-object227400126 ul, 
.art-object227400126 ol, 
.art-object227400126 li
{
  line-height: 26px;
}

.banners {
  
  float: left;
  width: 54%;
  margin-top: 25px;
}
.banners div {
    float: left;
}
.baner{
  width: 30%;
}
.ban1 {
    background: url("images/baner1.jpg") no-repeat scroll 0 0 transparent;
    display: block;
    height: 95px;
    width: 204px;
}
.ban2 {
    background: url("images/baner2.jpg") no-repeat scroll 0 0 transparent;
    background: url("images/banner_gastro_1.jpg") no-repeat scroll 0 0 transparent;
    display: block;
    height: 95px;
    width: 204px;
}
.ban3 {
    background: url("images/baner1.jpg") no-repeat scroll 0 0 transparent;
    background: url("images/banner_table_2.jpg") no-repeat scroll 0 0 transparent;
    display: block;
    height: 95px;
    width: 204px;
}
.vertical-line{
  background: url("images/vertical.png") no-repeat scroll 0 0 transparent;
  width: 1%;
  height: 71px;
  display: block;
  margin-right: 2px;
  float: left;
}
.cart
{
  float: left;
  display: block;
  margin-top: 35px;
  margin-left: -25px;
  margin-right: 15px;
  
  z-index: 3;
}
.basket{
  width: 18%;
  float: left;
  margin-top: 20px;
  position: relative;
  
}
.basket .vertical-line{
  margin-top: 7px;
}

.title-cart{
font-family: Arial;
color: #FF7900;
font-size: 16px;
font-weight: 700;
text-align: justify;
}
.quantity-cart{
font-family: Arial;
color:  #202020;
font-size: 15px;
font-weight: 700;
letter-spacing: -0.3px;
line-height: 23px;
text-align: left;
    padding-top: 12px;
}
.sum-cart{
font-family: Arial;
color:  #ff7900;
font-size: 20px;
font-weight: 700;
letter-spacing: -0.4px;
line-height: 23px;
text-align: left;
}
.issue-cart{
  font-family: Arial;
color:  #202020;
font-size: 12px;
font-weight: 400;
letter-spacing: 0.12px;
/*line-height: 25px;
*/text-align: left;
}
.social-but
{
    display: block;
   padding: 2px 0 6px 0;
}

.social-button{
  float:left;
  width: 3%;
  margin-top: 1px;
}
.social-button .youtube{
  background-image: url('images/youtube.png');
  background-repeat: no-repeat;
  height: 29px;
}
.social-button .googpe-plus{
	background-image: url('images/googpe-plus.png');
  background-repeat: no-repeat;
	height: 29px;
}

.social-button .grafik{
	background-image: url('images/grafik.png');
  background-repeat: no-repeat;
	height: 29px;
}
.social-button .youtube:hover{
  background-image: url('images/youtube-hover.png');
  background-repeat: no-repeat;
  height: 29px;
}
.social-button .googpe-plus:hover{
	background-image: url('images/googpe-plus-hover.png');
  background-repeat: no-repeat;
	height: 29px;
}

.social-button .grafik:hover{
	background-image: url('images/grafik-hover.png');
  background-repeat: no-repeat;
	height: 29px;
}

/*Шапка*/





/*основное меню*/


.responsive .art-header 
{
	/*
   background-image: url('images/header.jpg');
   background-position: center center;
   */
}

.art-header>.widget 
{
   position:absolute;
   z-index:101;
}

.art-nav
{
    margin: 0 auto;
    text-align: left;
    border-radius: 5px;
    background-color: #fe7900;
    width: 1182px;
    height: 45px;
}
.art-nav

ul.art-hmenu a, ul.art-hmenu a:link, ul.art-hmenu a:visited, ul.art-hmenu a:hover 
{
   outline: none;
   position: relative;
   z-index: 11;
}

ul.art-hmenu, ul.art-hmenu ul
{
   display: block;
   margin: 0;
   padding: 0;
   border: 0;
   list-style-type: none;
}

ul.art-hmenu li
{
   position: relative;
   z-index: 5;
   display: block;
   float: left;
   background: none;
   margin: 0;
   padding: 0;
   border: 0;
}

ul.art-hmenu li:hover
{
   z-index: 10000;
   white-space: normal;
}

ul.art-hmenu:after, ul.art-hmenu ul:after
{
   content: ".";
   height: 0;
   display: block;
   visibility: hidden;
   overflow: hidden;
   clear: both;
}

ul.art-hmenu, ul.art-hmenu ul 
{
   min-height: 0;
}

ul.art-hmenu 
{
   display: inline-block;
   
}

.art-nav:before 
{
   content:' ';
}

.desktop .art-nav{
   min-width: 320px;
   max-width: 1200px;
   width: 100%;
   padding-left: 0;
   padding-right: 0;
}

.art-hmenu-extra1
{
   position: relative;
   display: block;
   float: left;
   width: auto;
   height: auto;
   background-position: center;
}

.art-hmenu-extra2
{
   position: relative;
   display: block;
   float: right;
   width: auto;
   height: auto;
   background-position: center;
}

.art-hmenu
{
   float: left;
}

.art-menuitemcontainer
{
   margin:0 auto;
}
ul.art-hmenu>li {
   margin-left: 1px;
}
ul.art-hmenu>li:first-child {
   margin-left: 0;
}
ul.art-hmenu>li:last-child, ul.art-hmenu>li.last-child {
   margin-right: 0;
}

ul.art-hmenu>li>a
{
   background: #FF7B00;
   padding:0 22px;
   margin:0 auto;
   position: relative;
   display: block;
   height: 26px;
   cursor: pointer;
   text-decoration: none;
   color: #FCFCFD;
   line-height: 26px;
   text-align: center;
}

.art-hmenu>li>a, 
.art-hmenu>li>a:link, 
.art-hmenu>li>a:visited, 
.art-hmenu>li>a.active, 
.art-hmenu>li>a:hover
{
   /*font-size: 13px;
   font-family: ArialNarrow;
  font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   text-decoration: none;
   text-transform: uppercase;
   text-align: left;*/
   font-family: ArialNarrow;
color:  #ffffff;
font-size: 20px;/* Приближение из-за подстановки шрифтов */
font-weight: 400;
text-align: left;
}

.search
{  
      position: absolute;
    width: 429px;
    margin-left: 41px;


}
.search img{
      float: left;
}
input[type="text"]:focus { outline: none; }
form.art-search input[type="text"]
{
    background: #c65e00;
    margin: 0 auto;
    padding: 4px 0;
    height: 37px;
    font-family: Arial;
    color: #ffffff;
    font-size: 12px;  
    font-weight: 400;
    letter-spacing: 0.3px;
    text-align: left;
    border: none;
    border-radius: 0 5px 5px 0;

}
form.art-search 
{
   float: left;
    width: 91%;

    border: 0;
    display: block;
    position: relative;
    top: 0;
    padding: 0;
    margin: 5px;
    left: 0;
    line-height: 0;
}

form.art-search input, a.art-search-button
{
   top:0;
   right:0;
}

form.art-search>input, a.art-search-button{
   bottom:0;
   left:0;
   vertical-align: middle;
}

form.art-search input[type="submit"], input.art-search-button, a.art-search-button
{
   margin:0 auto;
}
form.art-search input[type="submit"], input.art-search-button, a.art-search-button {
   position: absolute;
    left: auto;
    display: block;
    border: none;
    background: url('images/search-icon.png') center center no-repeat;
    width: 49px;
    height: 100%;
    padding: 0;
    cursor: pointer;
    background-color: #e07521;
    width: 49px;
    border-radius: 0 5px 5px 0;
  }

  form.art-search input.art-search-button:hover
  {background-color:  #c65e00;}

a.art-search-button span.art-search-button-text {
   display: none;
}
input, select, textarea, a.art-search-button span
{
   vertical-align: middle;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
}

ul.art-hmenu>li>a.active
{
   background: #FE7900;
   padding: 8px 30px 0 25px;
   margin:0 auto;
   color: #FFFFFF;
   text-decoration: none;
}

ul.art-hmenu>li>a:visited, 
ul.art-hmenu>li>a:hover, 
ul.art-hmenu>li:hover>a {
   text-decoration: none;
}

ul.art-hmenu>li>a:hover, .desktop ul.art-hmenu>li:hover>a
{
    background-color: #c65e00;
    box-shadow: 0px 6px 0px 0px #b45500;
    width: auto;
    height: 39px;
    padding-top: 8px;
    border-radius: 0 0 5px 5px;
    margin-top: -2px;

}
ul.art-hmenu>li>a:hover, 
.desktop ul.art-hmenu>li:hover>a {
   color: #FFFFFF;
   text-decoration: none;
}

ul.art-hmenu>li:before
{
  position: absolute;
    content: ' ';
    left: -2px;
    background-color: #C55F02;
    width: 1px;
    height: 21px;
    margin-top: 12px;
}
ul.art-hmenu>li:first-child:before{
   display:none;
}

ul.art-hmenu ul
{
   display: none;
}

.art-sheet
{
   background: #FFFFFF;
   margin:10px auto 0;
   position:relative;
   cursor:auto;
   width: 100%;
   min-width: 320px;
   max-width: 1200px;
   z-index: auto !important;
}

.art-layout-wrapper
{
   position: relative;
   margin: 0 auto 0 auto;
   z-index: auto !important;
}

.art-content-layout
{
   display: table;
   width: 100%;
   table-layout: fixed;
}

.art-content-layout-row {
   display: table-row;
   position: relative;
}

.art-layout-cell
{
   display: table-cell;
   vertical-align: top;
}

/* need only for content layout in post content */ 
.art-postcontent .art-content-layout
{
   border-collapse: collapse;
}

.art-vmenublock
{
  margin-top: 34px;
}
div.art-vmenublock img
{
   margin: 0;
}

.art-vmenublockcontent
{
   margin:0 auto;
}

ul.art-vmenu, ul.art-vmenu ul
{
   list-style: none;
   display: block;
}

ul.art-vmenu, ul.art-vmenu li
{
   display: block;
   margin: 0;
   padding: 0;
   width: auto;
   line-height: 0;
}

ul.art-vmenu
{
   margin-top: 0;
   margin-bottom: 0;
}

ul.art-vmenu ul
{
   /*display: none;*/
   margin: 0;
   padding: 0;
   position: relative;
}
ul.art-vmenu ul .active-sub-menu
{
background-color:  #f0f0f0;
display: none;
}

ul.art-vmenu ul .active-sub-menu li
{
border: none;
}
ul.art-vmenu ul .active-sub-menu li a {
    color:  #202020 !important;
font-size: 14px !important;
font-weight: 400 !important;
/* fix letter-spacing: 0.35px !important; */
line-height: 12px !important;
text-align: left !important;
text-transform:none !important;
}

ul.art-vmenu ul.active
{
      display: block;
    background-color: #ffffff;
    background: #FFF;
}

ul.art-vmenu>li>div
{
    padding: 0 15px;
    margin: 0 auto;
    font-style: normal;
    text-decoration: none;
    min-height: 34px;
    line-height: 34px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #202020;
    height: 40px;
    font-family: Arial;
    color: #ffffff;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 0.095px;
    text-align: center;
}
ul.art-vmenu a 
{
  cursor: pointer;
  z-index: 1;
  position:relative;
  height: 37px;
  
}

ul.art-vmenu li{
   position:relative;
}

ul.art-vmenu>li
{
   margin-top: 1px;
}
ul.art-vmenu>li>ul
{
   padding: 0;
   margin-top: 0;
   margin-bottom: 0;
}
ul.art-vmenu>li:first-child
{
   margin-top:0;
}

/*ul.art-vmenu>li>a:hover, ul.art-vmenu>li>a.active:hover
{
   background: #FA681E;
   padding:0 15px;
   margin:0 auto;
}*/
ul.art-vmenu>li>a:hover, ul.art-vmenu>li>a.active:hover
{
   text-decoration: none;
}

ul.art-vmenu a:hover, ul.art-vmenu a.active:hover 
{
   color: #7D2B03;
}

ul.art-vmenu>li>a.active:hover>span.border-top, ul.art-vmenu>li>a.active:hover>span.border-bottom 
{
   background-color: transparent;
}

/*
ul.art-vmenu>li>a.active
{
   padding:0 15px;
   margin:0 auto;
   text-decoration: none;
   color: #FA5705;
}
*/

ul.art-vmenu>li:after, ul.art-vmenu>li>ul:after{
   display: block;
   position: absolute;
   content: ' ';
   left: 0;
   right: 0;
   top: -1px;
}

ul.art-vmenu>li:after, ul.art-vmenu>li>ul:after{
   z-index: 1;
   height: 0;
   border-bottom: 1px solid #D3D9DE;
}

ul.art-vmenu>li:first-child:before, ul.art-vmenu>li:first-child:after 
{
   display:none;
}

ul.art-vmenu>li>ul:before
{
   background: #DCE3EA;
   background: transparent;
   -webkit-border-radius:5px;
   -moz-border-radius:5px;
   border-radius:5px;
   margin:0 auto;
   display: block;
   position: absolute;
   content: ' ';
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
}

ul.art-vmenu li li a
{
   margin:0 auto;
   position: relative;
}
ul.art-vmenu ul li
{
   margin: 0;
   padding: 0;
}
ul.art-vmenu li li{
   position: relative;
       border-bottom: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
   
}
ul.art-vmenu li ul.active li:hover  {
  border-radius: 4px;
  background-color:  #fe7900;
}
ul.art-vmenu li ul.active li.active-tab{
  border-radius: 4px;
  background-color:  #fe7900;
}

ul.art-vmenu li ul.active li:active  {
  border-radius: 4px;
  background-color:  #fe7900;
}

ul.art-vmenu li ul.active li:hover a{
  color:  #ffffff;
font-size: 13px;
font-weight: 400;
padding-right: 10px;
text-align: left;
}
ul.art-vmenu li ul.active li.active-tab a{
  color:  #ffffff;
font-size: 13px;
font-weight: 400;
padding-right: 10px;
text-align: left;
}



ul.art-vmenu ul a
{
    position: relative;
    min-height: 24px;
    padding-left: 10px;
    line-height: 15px;
    text-decoration: none;
    margin-left: 0;
    margin-right: 0;
    text-transform: uppercase;
    vertical-align: middle;
    display: table-cell;
        padding-right: 10px;
}

ul.art-vmenu li ul.active li a{
	color: #202020;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    text-transform: uppercase;
}

ul.art-vmenu ul a:visited, ul.art-vmenu ul a.active
{
     font-style: normal;
    text-decoration: none;
    margin-left: 0;
    margin-right: 0;
    font-family: Arial;
    color: #202020;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    text-transform: uppercase;
}

ul.art-vmenu ul ul a
{
   padding-left: 18px;
}
ul.art-vmenu ul ul ul a
{
   padding-left: 81px;
}
ul.art-vmenu ul ul ul ul a
{
   padding-left: 108px;
}
ul.art-vmenu ul ul ul ul ul a
{
   padding-left: 135px;
}

/*ul.art-vmenu li li a:before
{
   content:url('images/vmenusubitemicon.png');
   margin-right:10px;
   bottom: 2px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;
}*/
.opera ul.art-vmenu li li a:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

ul.art-vmenu ul>li>a:hover, ul.art-vmenu ul>li>a.active:hover
{
   /*background: #EFF2F5;
   background: rgba(239, 242, 245, 0.4);
   margin:0 auto;*/
}
ul.art-vmenu ul li:hover a.active
{
   /*text-decoration: none;
   color: #F55505;*/
  
}



ul.art-vmenu ul a:hover:after
{
   background-position: center ;
}
ul.art-vmenu ul a.active:hover:after
{
   background-position: center ;
}
ul.art-vmenu ul a.active:after
{
   background-position: bottom ;
}

ul.art-vmenu li li a:hover:before
{
  /* content:url('images/vmenuhoveredsubitemicon.png');
   margin-right:10px;
   bottom: 2px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;*/
}
.opera ul.art-vmenu li li a:hover:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

ul.art-vmenu ul>li>a.active
{
   margin:0 auto;
}
ul.art-vmenu ul a.active, ul.art-vmenu ul a:hover, ul.art-vmenu ul a.active:hover
{
   /*text-decoration: none;
   color: #FA5705;*/
}

ul.art-vmenu li li a.active:before
{
   /*content:url('images/vmenuactivesubitemicon.png');
   margin-right:10px;
   bottom: 2px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;*/
}
.opera ul.art-vmenu li li a.active:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

ul.art-vmenu li li:after
{
   display: block;
   position: absolute;
   content: ' ';
   height: 0;
   top: -1px;
   left: 0;
   right: 0;
   z-index: 1;
   border-bottom: 1px solid #D2DBE4;
}

ul.art-vmenu li li:before
{
   display: block;
   position: absolute;
   content: ' ';
   left: 0;
   right: 0;
   top: -1px;
   z-index: 0;
   height: 1px;
}
ul.art-vmenu>li>ul>li:first-child:before, ul.art-vmenu>li>ul>li:first-child:after 
{
   display: none;
}

.art-block
{
   margin-top: 22px;
}
.art-block .active-menu{
  margin-bottom: 3px;
  border-top-left-radius: 5px;
border-top-right-radius: 5px;
background-color:  #202020;
height: 21px;
}

.art-block img{
  margin-bottom: 20px;
}

.art-blockcontent
{
   margin:0 auto;
   color: #303F50;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   line-height: 175%;
}
.art-blockcontent table,
.art-blockcontent li, 
.art-blockcontent a,
.art-blockcontent a:link,
.art-blockcontent a:visited,
.art-blockcontent a:hover
{
   color: #303F50;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   line-height: 175%;
}

.art-blockcontent p
{
   margin: 0 5px;
}

.art-blockcontent a, .art-blockcontent a:link
{
   color: #FB7C3C;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-blockcontent a:visited, .art-blockcontent a.visited
{
   color: #A9BACB;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
}

.art-blockcontent a:hover, .art-blockcontent a.hover
{
   color: #FA5705;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
}
/*.art-block ul>li:before
{
   content:url('images/blockbullets.png');
   margin-right:6px;
   bottom: 2px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;
   margin-left: -15px;
}*/
.opera .art-block ul>li:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

/*.art-block li
{
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   line-height: 175%;
   color: #4E6883;
   margin: 5px 0 0 10px;
}

.art-block ul>li, .art-block ol
{
   padding: 0;
}
*/


.art-breadcrumbs
{
   margin:0 auto;
}

a.art-button,
a.art-button:link,
a:link.art-button:link,
body a.art-button:link,
a.art-button:visited,
body a.art-button:visited,
input.art-button,
button.art-button
{
   text-decoration: none;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   position:relative;
   display: inline-block;
   vertical-align: middle;
   white-space: nowrap;
   text-align: center;
   color: #FFFFFF;
   margin: 0 5px 0 0 !important;
   overflow: visible;
   cursor: pointer;
   text-indent: 0;
   line-height: 24px;
   -webkit-box-sizing: content-box;
   -moz-box-sizing: content-box;
   box-sizing: content-box;
}

.art-button img
{
   margin: 0;
   vertical-align: middle;
}

.firefox2 .art-button
{
   display: block;
   float: left;
}



.art-block select 
{
   width:96%;
}

input.art-button
{
   float: none !important;
}

.art-button.active, .art-button.active:hover
{
   background: #A1ADBA;
   -webkit-border-radius:3px;
   -moz-border-radius:3px;
   border-radius:3px;
   border-width: 0;
   padding:0 21px;
   margin:0 auto;
}
.art-button.active, .art-button.active:hover {
   color: #FFFFFF !important;
}

.art-button.hover, .art-button:hover
{
   background: #8A99A8;
   -webkit-border-radius:3px;
   -moz-border-radius:3px;
   border-radius:3px;
   border-width: 0;
   padding:0 21px;
   margin:0 auto;
}
.art-button.hover, .art-button:hover {
   color: #FFFFFF !important;
}












input[type="text"], input[type="password"], input[type="email"], input[type="url"], textarea
{
   background: #F9FAFB;
   -webkit-border-radius:4px;
   -moz-border-radius:4px;
   border-radius:4px;
   border:1px solid #B9C2CB;
   margin:0 auto;
}
input[type="text"], input[type="password"], input[type="email"], input[type="url"], textarea
{
   width: 100%;
   padding: 4px 0;
 
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   text-shadow: none;
}
input.art-error, textarea.art-error
{
   background: #F9FAFB;
   border:1px solid #E2341D;
   margin:0 auto;
}
input.art-error, textarea.art-error {
   color: #3D5166 !important;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
}


label.art-checkbox:before
{
   background: #F9FAFB;
   -webkit-border-radius:1px;
   -moz-border-radius:1px;
   border-radius:1px;
   border-width: 0;
   margin:0 auto;
   width:16px;
   height:16px;
}
label.art-checkbox
{
   cursor: pointer;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   line-height: 16px;
   display: inline-block;
   color: #364049 !important;
}

.art-checkbox>input[type="checkbox"]
{
   margin: 0 5px 0 0;
}

label.art-checkbox.active:before
{
   background: #FC905A;
   -webkit-border-radius:1px;
   -moz-border-radius:1px;
   border-radius:1px;
   border-width: 0;
   margin:0 auto;
   width:16px;
   height:16px;
   display: inline-block;
}

label.art-checkbox.hovered:before
{
   background: #D9DEE3;
   -webkit-border-radius:1px;
   -moz-border-radius:1px;
   border-radius:1px;
   border-width: 0;
   margin:0 auto;
   width:16px;
   height:16px;
   display: inline-block;
}

label.art-radiobutton:before
{
   background: #F9FAFB;
   -webkit-border-radius:3px;
   -moz-border-radius:3px;
   border-radius:3px;
   border-width: 0;
   margin:0 auto;
   width:12px;
   height:12px;
}
label.art-radiobutton
{
   cursor: pointer;
   font-size: 13px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   line-height: 12px;
   display: inline-block;
   color: #364049 !important;
}

.art-radiobutton>input[type="radio"]
{
   vertical-align: baseline;
   margin: 0 5px 0 0;
}

label.art-radiobutton.active:before
{
   background: #B9C2CB;
   -webkit-border-radius:3px;
   -moz-border-radius:3px;
   border-radius:3px;
   border-width: 0;
   margin:0 auto;
   width:12px;
   height:12px;
   display: inline-block;
}

label.art-radiobutton.hovered:before
{
   background: #D9DEE3;
   -webkit-border-radius:3px;
   -moz-border-radius:3px;
   border-radius:3px;
   border-width: 0;
   margin:0 auto;
   width:12px;
   height:12px;
   display: inline-block;
}

.art-comments
{
   border-top:1px dotted #A1ADBA;
   margin:0 auto;
   margin-top: 25px;
}

.art-comments h2
{
   color: #23292F;
}

.art-comment-inner
{
   -webkit-border-radius:2px;
   -moz-border-radius:2px;
   border-radius:2px;
   padding:5px;
   margin:0 auto;
   margin-left: 96px;
}
.art-comment-avatar 
{
   float:left;
   width:80px;
   height:80px;
   padding:2px;
   background:#fff;
   border:1px solid #E2E8EE;
}

.art-comment-avatar>img
{
   margin:0 !important;
   border:none !important;
}

.art-comment-content
{
   padding:10px 0;
   color: #303F50;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-comment
{
   margin-top: 6px;
}

.art-comment:first-child
{
   margin-top: 0;
}

.art-comment-header
{
   color: #23292F;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   line-height: 100%;
}

.art-comment-header a, 
.art-comment-header a:link, 
.art-comment-header a:visited,
.art-comment-header a.visited,
.art-comment-header a:hover,
.art-comment-header a.hovered
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   line-height: 100%;
}

.art-comment-header a, .art-comment-header a:link
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: bold;
   font-style: normal;
   color: #758799;
}

.art-comment-header a:visited, .art-comment-header a.visited
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #758799;
}

.art-comment-header a:hover,  .art-comment-header a.hovered
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #758799;
}

.art-comment-content a, 
.art-comment-content a:link, 
.art-comment-content a:visited,
.art-comment-content a.visited,
.art-comment-content a:hover,
.art-comment-content a.hovered
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
}

.art-comment-content a, .art-comment-content a:link
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #CD4704;
}

.art-comment-content a:visited, .art-comment-content a.visited
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #3F5369;
}

.art-comment-content a:hover,  .art-comment-content a.hovered
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #CD4704;
}

.art-pager
{
   -webkit-border-radius:4px;
   -moz-border-radius:4px;
   border-radius:4px;
   padding:6px;
   margin:2px;
}

.art-pager>*:last-child
{
   margin-right:0 !important;
}

.art-pager>span {
   cursor:default;
}

.art-pager>*
{
   background: #D9DEE3;
   -webkit-border-radius:4px;
   -moz-border-radius:4px;
   border-radius:4px;
   padding:10px;
   margin:0 4px 0 auto;
   line-height: normal;
   position:relative;
   display:inline-block;
   margin-left: 0;
}

.art-pager a:link,
.art-pager a:visited,
.art-pager .active
{
   line-height: normal;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
   color: #1C2126
;
}

.art-pager .active
{
   background: #B6BFC9;
   padding:10px;
   margin:0 4px 0 auto;
   color: #FFFFFF
;
}

.art-pager .more
{
   background: #D9DEE3;
   margin:0 4px 0 auto;
}
.art-pager a.more:link,
.art-pager a.more:visited
{
   color: #411701
;
}
.art-pager a:hover
{
   background: #B9C2CB;
   padding:10px;
   margin:0 4px 0 auto;
}
.art-pager  a:hover,
.art-pager  a.more:hover
{
   text-decoration: none;
   color: #21262C
;
}
.art-pager>*:after
{
   margin:0 0 0 auto;
   display:inline-block;
   position:absolute;
   content: ' ';
   top:0;
   width:0;
   height:100%;
   right: 0;
   text-decoration:none;
}

.art-pager>*:last-child:after{
   display:none;
}

.art-commentsform
{
   background: #E2E8EE;
   background: transparent;
   padding:10px;
   margin:0 auto;
   margin-top:25px;
   color: #23292F;
}

.art-commentsform h2{
   padding-bottom:10px;
   margin: 0;
   color: #23292F;
}

.art-commentsform label {
   display: inline-block;
   line-height: 25px;
}

.art-commentsform input:not([type=submit]), .art-commentsform textarea {
   box-sizing: border-box;
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   width:100%;
   max-width:100%;
}

.art-commentsform .form-submit
{
   margin-top: 10px;
}
.art-post
{
       padding-left: 16px;
}
a img
{
   border: 0;
}

/*.art-article img, img.art-article, .art-footer img
{
   margin: 7px 7px 7px 7px;
}*/

.art-metadata-icons img
{
   border: none;
   vertical-align: middle;
   margin: 2px;
}

.art-article table, table.art-article
{
   border-collapse: collapse;
   margin: 1px;
}

.art-post .art-content-layout-br
{
   height: 0;
}

.art-article th, .art-article td
{
   padding: 2px;
   border: solid 1px #B9C2CB;
   vertical-align: top;
   text-align: left;
}

.art-article th
{
   text-align: center;
   vertical-align: middle;
   padding: 7px;
}

pre
{
   overflow: auto;
   padding: 0.1em;
}

.preview-cms-logo
{
   border: 0;
   margin: 1em 1em 0 0;
   float: left;
}

.image-caption-wrapper
{
   padding: 7px 7px 7px 7px;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

.image-caption-wrapper img
{
   margin: 0 !important;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

.image-caption-wrapper div.art-collage
{
   margin: 0 !important;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

.image-caption-wrapper p
{
   font-size: 80%;
   text-align: right;
   margin: 0;
}

.art-postheader
{
   color: #4E6883;
   margin: 5px 10px;
   font-size: 24px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
}

.art-postheader a, 
.art-postheader a:link, 
.art-postheader a:visited,
.art-postheader a.visited,
.art-postheader a:hover,
.art-postheader a.hovered
{
   font-size: 24px;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
}

.art-postheader a, .art-postheader a:link
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
   text-align: left;
   color: #FB7F41;
}

.art-postheader a:visited, .art-postheader a.visited
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
   text-align: left;
   color: #708BA9;
}

.art-postheader a:hover,  .art-postheader a.hovered
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
   text-align: left;
   color: #FB7C3C;
}

.art-postheadericons,
.art-postheadericons a,
.art-postheadericons a:link,
.art-postheadericons a:visited,
.art-postheadericons a:hover
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #4E6883;
}

.art-postheadericons
{
   padding: 1px;
   margin: 0 0 0 10px;
}

.art-postheadericons a, .art-postheadericons a:link
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   text-decoration: none;
   color: #FB722D;
}

.art-postheadericons a:visited, .art-postheadericons a.visited
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   text-decoration: none;
   color: #587493;
}

.art-postheadericons a:hover, .art-postheadericons a.hover
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: normal;
   text-decoration: underline;
   color: #E65005;
}

.art-postdateicon:before
{
   content:url('images/postdateicon.png');
   margin-right:6px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;
   bottom: auto;
}
.opera .art-postdateicon:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

.art-postauthoricon:before
{
   content:url('images/postauthoricon.png');
   margin-right:6px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;
   bottom: auto;
}
.opera .art-postauthoricon:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

.art-postediticon:before
{
   content:url('images/postediticon.png');
   margin-right:6px;
   bottom: 2px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;
}
.opera .art-postediticon:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

.art-postcontent ul>li:before,  .art-textblock ul>li:before
{
   content:url('images/postbullets.png');
   margin-right:10px;
   bottom: 2px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;
}
.opera .art-postcontent ul>li:before, .opera   .art-post ul>li:before, .opera   .art-textblock ul>li:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

.art-postcontent li, .art-post li, .art-textblock li
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #202020;
   margin: 3px 0 0 11px;
}

.art-postcontent ul>li, .art-post ul>li, .art-textblock ul>li, .art-postcontent ol, .art-post ol, .art-textblock ol
{
   padding: 0;
}

.art-postcontent ul>li, .art-post ul>li, .art-textblock ul>li
{
   padding-left: 17px;
}

.art-postcontent ul>li:before,  .art-post ul>li:before,  .art-textblock ul>li:before
{
   margin-left: -17px;
}

.art-postcontent ol, .art-post ol, .art-textblock ol, .art-postcontent ul, .art-post ul, .art-textblock ul
{
   margin: 0 0 5px 0;
}

.art-postcontent li ol, .art-post li ol, .art-textblock li ol, .art-postcontent li ul, .art-post li ul, .art-textblock li ul
{
   margin: 0.5em 0 0.5em 11px;
}

.art-postcontent li, .art-post li, .art-textblock li
{
   margin: 3px 0 0 0;
}

.art-postcontent ol>li, .art-post ol>li, .art-textblock ol>li
{
   /* overrides overflow for "ul li" and sets the default value */
  overflow: visible;
}

.art-postcontent ul>li, .art-post ul>li, .art-textblock ul>li
{
   /* makes "ul li" not to align behind the image if they are in the same line */
  /*overflow-x: visible;
   overflow-y: hidden;*/
}

blockquote
{
   background: #EFF2F5 url('images/postquote.png') no-repeat scroll;
   padding:10px 10px 10px 47px;
   margin:10px 0 0 25px;
   color: #0D1216;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: italic;
   text-align: left;
   /* makes block not to align behind the image if they are in the same line */
  overflow: auto;
   clear:both;
}
blockquote a, .art-postcontent blockquote a, .art-blockcontent blockquote a, .art-footer blockquote a,
blockquote a:link, .art-postcontent blockquote a:link, .art-blockcontent blockquote a:link, .art-footer blockquote a:link,
blockquote a:visited, .art-postcontent blockquote a:visited, .art-blockcontent blockquote a:visited, .art-footer blockquote a:visited,
blockquote a:hover, .art-postcontent blockquote a:hover, .art-blockcontent blockquote a:hover, .art-footer blockquote a:hover
{
   color: #0D1216;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   font-weight: normal;
   font-style: italic;
   text-align: left;
}

/* Override native 'p' margins*/
blockquote p,
.art-postcontent blockquote p,
.art-blockcontent blockquote p,
.art-footer blockquote p
{
   margin: 0;
   margin: 5px 0;
}

.Sorter img
{
   border:0;
   vertical-align:middle;
   padding:0;
   margin:0;
   position:static;
   z-index:1;
   width: 12px;
   height: 6px;
}

.Sorter a
{
   position:relative;
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #647587;
}

.Sorter a:link
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #647587;
}

.Sorter a:visited, .Sorter a.visited
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #647587;
}

.Sorter a:hover, .Sorter a.hover
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color: #4C5967;
}

.Sorter
{
   font-family: Arial, 'Arial Unicode MS', Helvetica, Sans-Serif;
   color:#364049;
}


.art-lightbox-wrapper 
{
   background: #333;
   background: rgba(0, 0, 0, .8);
   bottom: 0;
   left: 0;
   padding: 0 100px;
   position: fixed;
   right: 0;
   text-align: center;
   top: 0;
   z-index: 1000000;
}

.art-lightbox,
.art-lightbox-wrapper .art-lightbox-image
{
   cursor: pointer;
}

.art-lightbox-wrapper .art-lightbox-image
{
   border: 6px solid #fff;
   border-radius: 3px;
   display: none;
   max-width: 100%;
   vertical-align: middle;
}

.art-lightbox-wrapper .art-lightbox-image.active
{
   display: inline-block;
}

.art-lightbox-wrapper .lightbox-error
{
   background: #fff;
   border: 1px solid #b4b4b4;
   border-radius: 10px;
   box-shadow: 0 2px 5px #333;
   height: 80px;
   opacity: .95;
   padding: 20px;
   position: fixed;
   width: 300px;
   z-index: 100;
}

.art-lightbox-wrapper .loading
{
   background: #fff url('images/preloader-01.gif') center center no-repeat;
   border: 1px solid #b4b4b4;
   border-radius: 10px;
   box-shadow: 0 2px 5px #333;
   height: 32px;
   opacity: .5;
   padding: 10px;
   position: fixed;
   width: 32px;
   z-index: 10100;
}

.art-lightbox-wrapper .arrow
{
   cursor: pointer;
   height: 100px;
   opacity: .5;
   filter: alpha(opacity=50);
   position: fixed;
   width: 82px;
   z-index: 10003;
}

.art-lightbox-wrapper .arrow.left
{
   left: 9px;
}

.art-lightbox-wrapper .arrow.right
{
   right: 9px;
}

.art-lightbox-wrapper .arrow:hover
{
   opacity: 1;
   filter: alpha(opacity=100);
}

.art-lightbox-wrapper .arrow.disabled 
{
   display: none;
}

.art-lightbox-wrapper .arrow-t, 
.art-lightbox-wrapper .arrow-b
{
   background-color: #fff;
   border-radius: 3px;
   height: 6px;
   left: 26px;
   position: relative;
   width: 30px;
}

.art-lightbox-wrapper .arrow-t
{
   top: 38px;
}

.art-lightbox-wrapper .arrow-b
{
   top: 50px;
}

.art-lightbox-wrapper .close
{
   cursor: pointer;
   height: 22px;
   opacity: .5;
   filter: alpha(opacity=50);
   position: fixed;
   right: 39px;
   top: 30px;
   width: 22px;
   z-index: 10003;
}

.art-lightbox-wrapper .close:hover 
{
   opacity: 1;
   filter: alpha(opacity=100);
}

.art-lightbox-wrapper .close .cw, 
.art-lightbox-wrapper .close .ccw
{
   background-color: #fff;
   border-radius: 3px;
   height: 6px;
   position: absolute;
   left: -4px;
   top: 8px;
   width: 30px;
}

.art-lightbox-wrapper .cw
{
   transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   /* IE 9 */
    -webkit-transform: rotate(45deg);
   /* Safari and Chrome */
    -o-transform: rotate(45deg);
   /* Opera */
    -moz-transform: rotate(45deg);
/* Firefox */}

.art-lightbox-wrapper .ccw
{
   transform: rotate(-45deg);
   -ms-transform: rotate(-45deg);
   /* IE 9 */
    -webkit-transform: rotate(-45deg);
   /* Safari and Chrome */
    -o-transform: rotate(-45deg);
   /* Opera */
    -moz-transform: rotate(-45deg);
/* Firefox */}

.art-lightbox-wrapper .close-alt, 
.art-lightbox-wrapper .arrow-right-alt, 
.art-lightbox-wrapper .arrow-left-alt 
{
   color: #fff;
   display: none;
   font-size: 2.5em;
   line-height: 100%;
}

.ie8 .art-lightbox-wrapper .close-alt, 
.ie8 .art-lightbox-wrapper .arrow-right-alt, 
.ie8 .art-lightbox-wrapper .arrow-left-alt 
{
   display: block;
}

.ie8 .art-lightbox-wrapper .cw, 
.ie8 .art-lightbox-wrapper .ccw 
{
   display: none;
}

.art-content-layout .art-sidebar1
{
   background: #EFF2F5;
   background: transparent;
   margin:0 auto;
   width: 313px;
}

.art-content-layout .art-content
{
   margin:0 auto;
   margin-top: 34px;
   display: block;
   position: relative
}

.cart-popup{
     position: absolute;
    top: 23px;
    left: 0;
    z-index: 1000;
    margin-left: 16px;
    width: 98%;
}
.cart-header{
  border-top-left-radius: 4px;
border-top-right-radius: 4px;
background-color:  #fe7900;
height: 40px;
}
.cart-title{
  color:  #ffffff;
font-size: 19px;
font-weight: 700;
line-height: 14px;
float: left;
    margin: 13px 0 0 22px;
}
.close_parent{
  float: right;
  width: 19px;
height: 21px;
background-image: url('images/close-white.png');
    margin: 11px 6px 0 0;
}
.close_parent:hover{
  background-image: url('images/close-black.png');
}
.cart-body{
border-radius: 0 0 4px 4px;
    border-style: solid;
    border-width: 1px;
    border-color: #fe7900;
    box-sizing: border-box;
    background-color: #ffffff;
    padding: 24px;
}
.cart-body:after{
clear: both;
content: '';
display: block;
}
.cart-body ul li{
border-bottom: 1px dotted #b8b8b8;
height: 130px;
height: 140px;
}
.cart-body ul li.border-down{
  border-bottom: 1px solid #b8b8b8;
}
.cart_item_name{
      width: 100%;
    float: left;
        margin: 24px 0 20px 0;
}
.ui-cart-button .button{
  width: 193px;
}
.ui-cart-button a{
  text-decoration: none;
}
.cart-detail{
    float: left;
        margin-right: 16px;
}
.continue{
  float: left !important;
}
.first_col, .goods-link, .cart_item_data .old-cena, .cart_item_data .current-cena, .cart_item_data .cart_counter, .cl2{
  float: left;
}
.first_col{
  width: 111px;
height: 108px;
    margin: 0 10px 0 10px;
}
.cl2{
  width: 80%;
  padding-left: 24px;
}
.cart_item_name a{
  font-size: 19px;
letter-spacing: 0.475px;
/* line-height: 12px; */
text-align: left;
color:  #202020;
text-decoration: none;
}
.cart_item_data{
  width: 100%;
  float: left;
}
.cart_item_data .current-cena{
  font-size: 19px;
}
.mar-r{
  margin-right: 20px;
}
.cart_counter{
width: 27px;
height: 24px;
}
.increase{

}
.cart_delete{
  float: right;
}
.ri{
  float: right !important;
      margin-right: 44px;
}
.box-itog{
          margin-top: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
}
.box-itog{
  clear: both;
  content: '';
  display: block;
}
.cart_bottom_title{
  font-size: 19px;
font-weight: 400;
line-height: 12px;
text-align: left;
float: left;
width: 27%;
}
.cart_bottom_count{
  font-size: 17px;
font-weight: 400;
line-height: 12px;
text-align: left;
float: left;
width: 32%
}
.itog-right{
  text-align: right !important;
}
.cart_bottom_price{
  color:  #fe7900;
font-size: 19px;
font-weight: 400;
line-height: 12px;
text-align: left;
}
.frame{
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;

background-color: #ffffff; 

border: 1px solid #e1e1e1;
    display: inline-block;
    margin-bottom: 28px;
    margin-bottom: 15px;
        position: relative;
}

.frame h3{
font-family: Arial;
    color: #1d1d1d;
    font-size: 19px;
    font-weight: 700;
    text-align: center;
background-color: #fff; 
    padding: 0 55px;
    margin-top: -18px;
    display: inline-block;
}
.frame-title{
     text-align: center;
    position: absolute;
    width: 100%;
    top: -5px;
        z-index: 999;
}
.advantages-icon{
  width: 20%;
  float: left;
      text-align: center;
      padding-top: 17px;
       margin: 0 21px;
}
.advantages-icon img{
    padding-bottom: 5px;
}
.advantages-name{
  font-family: Arial;
color:  #1c1c1c;
font-size: 12px;
font-weight: 700;
text-align: center;
text-transform: uppercase;
    padding: 0 11px 22px;
}
.frame .card{
    float: left;
    width: 158px;
    padding: 32px 23px 0 23px;
    text-align: center;
        border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
        height: 316px;
	/* ? height: 286px; */
        position: relative;


}
.card .label{
  position: absolute;
  display: block;
  z-index: 999;
     top: 139px;
    left: 38px;
}
.card .label b{
        padding-top: 8px;
    display: block;
    font-weight: 400;
    color: #202020;
    font-size: 12px;
    text-transform: uppercase;
}
.card .akcia{
  background-image: url('images/label-yelo.png');
  background-repeat: no-repeat;
  width: 78px;
height: 33px;
}
.card .top{
  background-image: url('images/label-gren.png');
  background-repeat: no-repeat;
  width: 100px;
height: 33px;
}
.card .top b{
  color:  #ffffff;
}
.art-layout-cell .frame .vertical-line{
        height: 245px;
    background-repeat: repeat-y;
    margin: 32px 0;
    width: 1px;
}
/*
.arrow-left{
  float: left;
  background-image: url('images/left-arrow.png');
  margin-top: 130px;
  width: 15px;
height: 40px;
margin-left: 5px;
}
.arrow-left:hover{
  background-image: url('images/left-arrow-hower.png');
}
.arrow-right{
  float: left;
  background-image: url('images/right-arrow.png');
  margin-top: 130px;
  width: 15px;
height: 40px;
margin-right: 5px;
}
.arrow-right:hover{
  background-image: url('images/right-arrow-hower.png');
}*/
.card-art ul{
  position: relative;
      overflow: hidden;
        height: 320px;
        /*width: 845px;
*/
}
.card-art ul:after{
clear:both;
content: '';
display:block;
}

.card-art ul li{
    float: left;
    width: 160px !important;
    padding: 0px 23px 0 23px;
    text-align: center;
    position: relative;
    margin: 0 auto;
}
.card-art ul li:after{
   /*   background: url("images/vertical.png") repeat-y scroll 0 0 transparent;
    width: 1px;
    height: 222px;
    display: block;
    margin-right: 2px;
    float: left;
    position: absolute;
    top: 56px;
    left: 230px;
    content: '';*/
}
a.jcarousel-control-prev.left-arrow{
  float: left;
  background-image: url('images/left-arrow.png');
  width: 15px;
height: 40px;
margin-right: 5px;
position: absolute;
    top: 75px;
    left: 4px;
content: '';

}
.card-art a.jcarousel-control-prev.left-arrow, .card-art a.jcarousel-control-next.right-arrow{
top: 135px;
}
a.jcarousel-control-prev.left-arrow:hover{
  background-image: url('images/left-arrow-hower.png');
}
a.jcarousel-control-next.right-arrow{
  float: left;
    background-image: url('images/right-arrow.png');
    width: 15px;
    height: 40px;
    margin-right: 5px;
    position: absolute;
    top: 75px;
    left: auto;
    right: 0;
    content: '';
}
a.jcarousel-control-next.right-arrow:hover{
  background-image: url('images/right-arrow-hower.png');

}

.card-art.jcarousel{
  overflow: hidden;
  position: relative;
  height: 320px;
}
.card img{
 
}
.card p{
font-family: Arial;
    color: #1c1c1c;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.325px;
    text-align: center;
    /* fix-23 transform: scaleX(1.0715); */
    padding: 5px 15px;
       /* XXX min-height: 90px; */
       min-height: 90px;
    
}
.card a{
	text-decoration: none;
}

/*  check + best  */
 .check-y2{
  background-image: url('images/check-y.png');
  background-repeat: no-repeat;
  width: 18px;
  width: 128px;
  padding-top: 5px;
  height: 21px;
  cursor: pointer;
  position: relative;
}
.check-n2{
  background-image: url('images/check-n.png');
  background-repeat: no-repeat;
  width: 16px;
  width: 126px;
  padding-top: 2px;
  height: 16px;
  cursor: pointer;
  position: relative;
}
.check-w2{
  background-image: url('images/check-w.png');
  background-repeat: no-repeat;
  width: 16px;
  width: 126px;
  padding-top: 2px;
  height: 16px;
  cursor: pointer;
  position: relative;
}
.check-z2{
  background-image: url('images/check-z.png');
  background-repeat: no-repeat;
  width: 16px;
  width: 126px;
  padding-top: 2px;
  height: 16px;
  cursor: pointer;
  position: relative;
}
/*  check + best  */

 .check-y{
  float: left;
  background-image: url('images/check-y.png');
  background-repeat: no-repeat;
  width: 18px;
height: 21px;
cursor: pointer;
position: relative;
}
.card .check-y:hover::after{
    content: attr(data-title);
    position: absolute;
    left: 12px;
    top: 17px;
    padding: 3px 0;
    color: #027f61;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 90px;
    height: 23px;
}

 .check-n{
  float: left;
  background-image: url('images/check-n.png');
  background-repeat: no-repeat;
  width: 16px;
height: 16px;
cursor: pointer;
position: relative;
}
.card .check-n:hover::after{
content: attr(data-title);
    position: absolute;
    left: 13px;
    top: 12px;
    padding: 3px 0;
    color:  #1d1d1d;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 100px;
height: 23px;
}
 .check-w{
  float: left;
  background-image: url('images/check-w.png');
  background-repeat: no-repeat;
  width: 16px;
height: 16px;
cursor: pointer;
position: relative;
}
.card .check-w:hover::after{
content: attr(data-title);
    position: absolute;
    left: 13px;
    top: 12px;
    padding: 3px 0;
    color: #FE7900;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 124px;
height: 23px;
}
 .check-z{
  float: left;
  background-image: url('images/check-z.png');
  background-repeat: no-repeat;
  width: 16px;
height: 16px;
cursor: pointer;
position: relative;
}
.card .check-z:hover::after{
content: attr(data-title);
    position: absolute;
    left: 13px;
    top: 12px;
    padding: 3px 0;
        color: #FE2C00;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 100px;
height: 23px;
}
.cena{
  font-family: Arial;
color:  #fe7900;
font-size: 18px;
font-weight: 700;
text-align: right;
}
.cena span{
  font-family: Arial;
color:  #fe7900;
font-size: 14.08px;
font-weight: 400;
text-align: center;
}
.read-more{
  font-family: Arial;
color:  #1c1c1c;
font-size: 12px;
font-weight: 400;
letter-spacing: -0.20px;
    float: left;
        padding-top: 24px;
}
.read-more:hover{
	color:  #027f61;
}
.button{
  border-radius: 4px;
background-color:  #fe7900;
width: 93px;
height: 30px;
    float: right;
        margin-top: 3px;
}
.button:hover{
	background-color:  #1d1d1d;
	color:  #ffffff;
}
.card p:hover{
	color:  #fe7900;
}
.card:hover{
border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    display: inline-block;
    height: 316px;
    /* ? height: 286px; */

}

.button p{
color:  #ffffff;
font-size: 15px;
text-align: center;
    padding-top: 6px;
}
.art-card{
      padding: 5px 0 18px 0;
    display: inline-block;
        width: 160px;
}
.frame .category{
  /*background: url("images/vertical.png") repeat-y scroll 0 0 transparent;
      height: 245px;
    margin: 0px;
    width: 1px;*/
    display: inline-block;
    padding-top: 30px; 
}
.frame .category .vertical-line{
    margin: 5px 0 0 0;
    height: 241px;
}
.frame .category ul {

  float: left;

}
.frame .category ul li{
     padding-bottom: 14px;
    margin-left: 5px;
    margin-right: 18px;


}
.frame .category ul li a{
  font-family: Arial;
color:  #d16e13;
font-size: 13px;
font-weight: 400;
line-height: 18px;
text-align: left;
text-transform: uppercase;
text-decoration: none;
}

.frame .category ul li a:hover:before{font-family: Arial;
 content:url('images/check-hover.png');
}
.frame .category ul li a:hover{font-family: Arial;
color:  #202020;
font-size: 13px;
font-weight: 400;
line-height: 18px;
text-align: left;
}

.frame .category ul li a:before
{
   content:url('images/check.png');
   margin-right:12px;
   bottom: 2px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;
}
.text{
  padding: 26px 24px 20px 24px;
  color:  #202020;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: justify;
}
.text-list{
      padding: 0 23px 20px 23px;
    }
.text-list ul{
  margin: 0px;
}
.frame h4{
color:  #fe7900;
font-size: 16px;
font-weight: 700;
letter-spacing: 0.4px;
text-align: center;
    padding-bottom: 10px;
}
.text p{
  text-indent: 38px;
color:  #202020;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 21px;
text-align: justify;
}
.text-list ul li{
color:  #202020;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 21px;
text-align: justify;
    padding:0 0 10px 40px !important;
    background-image: url('images/check-rhombus.png');
    background-repeat: no-repeat;
        border: none !important;
}
.orang{
  color:  #fe7900;
}


.data{

color:  #202020;
font-size: 13px;
font-weight: 700;
text-align: right;
    padding: 30px 30px 0 0;
}
.news-list{
  width: 49.5%;
  float: left;
}
.frame .news-list  h4{
  font-family: Arial;
/* color:  #202020; */
font-size: 18px;
font-weight: 700;
letter-spacing: 0.4px;
text-align: center;
    padding-bottom: 10px;
} 


 .frame span.read-more:hover {
color:  #027f61;
}
.data:after{
   content:url('images/calendar.png');
   float: right;
   padding-left: 12px;
}
.frame .news h4{
  text-align: left;
  padding: 10px 0 10px 25px;
}
.frame .news h4:hover{
	color:  #fe7900;
}
.frame .news span{
  padding: 0 25px;
      display: block;
      color:  #202020;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.28px;
line-height: 21px;
text-align: left;
}
.frame .news .read-more {
  color:  #202020;
font-size: 13px;
font-weight: 400;
    float: right;
    padding-bottom: 28px;
}
.frame .news .vertical-line {
  margin-top: 40px;
  height: 105px;
}

.frame a{
	text-decoration: none;
}
.articles-list{
  width: 49.5%;
  float: left;
}

.frame .articles h4{
  text-align: left;
  padding: 36px 0 10px 25px;
}
.frame .articles span{
  padding: 0 25px;
      display: block;
      color:  #202020;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.28px;
line-height: 21px;
text-align: left;
}
.frame .articles .read-more {
  color:  #202020;
font-size: 13px;
font-weight: 400;
    float: right;
    padding-bottom: 28px;
}
.frame .articles .vertical-line {
  margin-top: 40px;
  height: 105px;
}
.frame .articles  h4{
font-size: 18px;
} 
.frame .articles  h4:hover{
color:  #202020;
} 

.frame .partner-art--OFF li.span img{
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: url(grayscale.svg#greyscale); /* Firefox 4+ */
  filter: gray; /* IE 6-9 */
   filter: grayscale(100%);
    float: left;
  }
.frame .partner-art--OFF li.span img:hover{
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: none;
    
  }
.frame .partner-art li.span{
  padding: 50px 28px;
  float: left;
  width: 105px !important;
}
.arrow-partner-left{
  margin-left: 20px;
  float: left;
}
.arrow-partner-right{
  margin-right: 20px;
  float: left;
}

.partner-art{
  width: 94%;
  float: left;
      margin-left: 27px;
}
.partner{
  height: 188px;
}
/*Футер*/
.art-footer
{
   border-top: 4px solid #fe7900;
    position: relative;
    text-align: center;
    padding: 0;
    background-color:  #373737;
}

.art-footer a,
.art-footer a:link,
.art-footer a:visited,
.art-footer a:hover,
.art-footer td, 
.art-footer th,
.art-footer caption
{
   font-family: Arial;
color:  #ffffff;
font-size: 14px;
font-weight: 400;
line-height: 21px;
}

.art-footer p 
{
   padding:0;
   text-align: center;
   color:  #ffffff;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: justify;
}

.art-footer a,
.art-footer a:link
{
   
   text-decoration: none;
}

.art-footer a:visited
{
   
}

.art-footer a:hover
{
   
}

.art-footer h1
{
   
}

.art-footer h2
{
  
}

.art-footer h3
{
  color:  #fe7900;
font-size: 13px;
font-weight: 400;
line-height: 21px;
text-align: left;
text-transform: uppercase;
    padding-bottom: 25px;
}

.art-footer h4
{
   
}

.art-footer h5
{
   
}

.art-footer h6
{
  
}

.art-footer img
{
   border: none;
   margin: 0;
}

.art-footer-inner
{
   margin: 0 auto;
   min-width: 320px;
   max-width: 1200px;
   width:100%;
   padding-top:25px;
   padding-right: 25px;
   padding-left: 25px;
}
.art-footer-inner h3{
  
}
.art-footer .social p 
{
       float: right;
    text-align: right;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    padding: 0 30px 0 130px;
    position: relative;
    display: inline-block;
}
.footer-content{
  float:left;
  padding-left: 30px;
}
.social{
  width: 14%;
      position: relative;
}
.social a .yt{
  background-image: url('images/f-youtube.png');
  background-repeat: no-repeat;
  height: 43px;
  width: 44px;
  padding: 6px 0;

}
.social a .gp{
  background-image: url('images/f-google-plus.png');
  background-repeat: no-repeat;
  height: 43px;
  width: 44px;
  padding: 6px 0;

}
.social a .yt:hover{
	background-image: url('images/f-youtube-hover.png');
  background-repeat: no-repeat;
}
.social a .gp:hover{
	background-image: url('images/f-google-plus-hover.png');
  background-repeat: no-repeat;
}
.arrow-f{
	background-image: url('images/arrow-f.png');
  background-repeat: no-repeat;
	    width: 32px;
    height: 130px;
    position: absolute;
        top: 21px;
    left: 135px;
}

.art-footer-inner .footer-content a img{
  float: left;
  padding-right: 35%;
  padding-bottom: 10px;
}
.info{
width: 18%;
}
.info ul{
float: left;
}
.info ul li{
	text-align: left;
}

.delivery{
width: 40%;
}
.delivery p{
	padding-right: 40px;
}
.contacts{
  width: 17%;
}
.contacts ul li.footer-grafic:before{
content: url('images/grafick.png');
padding-right: 20px;
float: left;
}
.contacts ul li.footer-phone:before{
content: url('images/phone.png');
float: left;
padding-right: 20px;
padding-top: 16px;

}
.contacts ul li{
	text-align: left;
}
.contacts ul li.footer-phone{
	text-align: left;
	padding-top: 20px;
}
.contacts h3{
	padding-left: 42px;
}
.contacts .footer-grafic span{

}
.footer-vertical-line{
  background-image: url('images/footer-vertical-line.jpg');
  background-repeat:repeat-y;
width: 2px;
height: 226px;
float: left;
}


/*.art-footer ul>li:before
{
   content:url('images/footerbullets.png');
   margin-right:6px;
   bottom: 2px;
   position:relative;
   display:inline-block;
   vertical-align:middle;
   font-size:0;
   line-height:0;
   margin-left: -13px;
}*/
.opera .art-footer ul>li:before
{
   /* Vertical-align:middle in Opera doesn't need additional offset */
    bottom: 0;
}

.art-footer li
{
   font-family: Arial;
color:  #ffffff;
font-size: 14px;
font-weight: 400;
line-height: 21px;
}

.art-footer ul>li, .art-footer ol
{
   padding: 0;
}

.art-footer ul>li a:hover
{
   color:  #fe7900;
}


/*Футер*/

.art-shapes-responsive{
  display: none;
}
.search-desktop-responsive{
  display: none;
}
.phone-responsive{
  display: none;
}
.card-responsive, .cart-detail-responsive, .menu-mob{
  display: none;
}
.breadcrumbs{
  padding-bottom: 22px;
}
.breadcrumbs h2
{
  color:  #202020;
font-size: 13px;
font-weight: 400;
letter-spacing: 0.325px;
line-height: 12px;
text-align: left;
}
.breadcrumbs:after{
clear:both;
content: '';
display:block;
}
.breadcrumbs ul.top_links li {
   
    color:  #fe7900;
font-size: 13px;
font-weight: 400;
letter-spacing: 0.325px;
line-height: 12px;
text-align: left;
float: left;
}
.breadcrumbs ul.top_links{
  margin: 0 !important;
}
.top_links li a {
   color:  #202020;
font-size: 13px;
font-weight: 400;
letter-spacing: 0.325px;
line-height: 12px;
text-align: left;
text-decoration: none;
}
.top_links li a:hover {

   color:  #fe7900;

}
.top_links li {
padding: 0 !important;

}
.top_links li a:after{
    content: '→';
    margin-left: 5px;
    margin-right: 5px;
 
}
.title-page:after{
clear:both;
content: '';
display:block;
}
.title-page{
padding-bottom: 16px;
}
.title-page h1
{
  color:  #fe7900;
font-size: 28px;
font-weight: 400;
line-height: 29px;

text-transform: uppercase;
}

.catalog ul.table-goods{
  margin-top: 38px;
      text-align: center;
}
.catalog .table-goods li {
list-style: none !important;
    float: left;
    overflow: hidden;
    padding: 0;
    min-height: 190px;
    background: none;
    width: 210px;
    height: 233px;
    margin-left: 2px;
        margin-top: 2px;
        border-radius: 4px;
border-style: solid;
border-width: 1px;
border-color:  #fff;
padding: 0px;
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
  }
  .catalog .table-goods li:hover a {
color:  #fe7900;
  }
  .catalog .table-goods li:hover{
    border-radius: 4px;
border-style: solid;
border-width: 1px;
border-color:  #fe7900;
  }
.catalog .table-goods li img{
    margin: 0 auto;
    display: block;
}
.catalog .table-goods li a{
  color:  #202020;
font-size: 16px;
font-weight: 400;
line-height: 18px;
    padding-top: 16px;
    display: block;
    text-align: center;
}
.next-section:after{
clear:both;
content: '';
display:block;
}

.next-section{
  border-radius: 4px;
background-color:  #fe7900;

}
.next-section h3{
  color:  #ffffff;
font-size: 19px;
font-weight: 700;
letter-spacing: 0.475px;
line-height: 12px;
text-align: justify;
background-color: transparent;
    margin: 20px 0 0 36px;
padding: 0;
}
.next-section h4{
color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: justify;
    padding-top: 10px;

}
.next-section ul{
      margin: 0 0 17px 36px;
}
.next-section li a{
  color:  #ffffff;
  text-transform: uppercase;
  font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 25px;
text-decoration: underline;
text-align: justify;
}
.next-section ul li a:before {
    content: url('images/check-w1.png');
    margin-right: 12px;
    bottom: 2px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
}
.next-section li a:hover{
  color: #202020;
}
.next-section ul li a:hover:before{font-family: Arial;
 content:url('images/check-hover.png');
}
.sub-text ul.sub-phone:after{
clear:both;
content: '';
display:block;
}
.sub-text ul.sub-phone{
  text-align: center;
   padding-bottom: 12px;

}

.sub-text ul.sub-phone li.sub-number{
  color:  #202020;
font-size: 15px;
font-weight: 400;
letter-spacing: 0.375px;
line-height: 28px;
text-align: left;
float: left;
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
    margin: 0 auto;
    margin: 0px 54px;
}
.sub-text ul.sub-phone li.sab-kievstar:before{
  content: url('images/kievstar-black.png');
  width: 21px;
height: 20px;
display: block;
float: left;
padding-right: 15px;

}
.sub-text ul.sub-phone li.sab-mtc:before{
content: url('images/mtc-black.png');
  width: 21px;
height: 20px;
display: block;
float: left;
    padding-right: 15px;
    padding-top: 3px;
}
.sorting{
  width: 100%;
  display:block;
  margin-bottom: 10px;
}
.sorting:after{
clear:both;
content: '';
display:block;
}
.sorting .title-sorting{
  color:  #fe7900;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 23px;
text-align: left;
float: left;
    padding-right: 10px;
}
.sorting .selection-manufacturer{
  
}
.sorting .selection-manufacturer select{
  float: left;
  border-radius: 3px;
border-style: solid;
border-width: 1px;
border-color:  #e1e1e1;
box-sizing: border-box;
background-color:  #ffffff;
width: 212px;
height: 23px;
margin-right: 10px;
}
.sorting .selection-manufacturer select:focus {
  outline: none; 
}
input.button-black{
  float: left;
  border-radius: 3px;
background-color:  #202020;
width: 76px;
height: 23px;
color:  #ffffff;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 23px;
text-align: center;
    border: #202020;
}
input.button-black:hover{
    background-color: #fe7900;
    cursor: pointer;
}
.sorting .by-price {
  float: right;
}
.sorting .by-price .updown-manufacturer{
  float: left;
}
.sorting .by-price .title-sorting{
  float: left;
}
.sorting input.button-up-down{
  float: right;
  width: 24px;
height: 23px;
}
.subdirectory{
  padding-bottom: 36px;
  margin-bottom: 10px;
}
.subdirectory .card-art ul{
  height: 100% !important;
}
.pager:after{
clear:both;
content: '';
display:block;
}
.pager{
text-align: center;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 28px;
}
.pager .pagination{
      display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
}

.pager .button-pager{
  border-radius: 3px;
background-color:  #fe7900;
width: 37px;
height: 24px;
border-style: solid;
border-width: 1px;
border-color:  #fff;
}
.pager .button-pager:hover{
border-style: solid;
border-width: 1px;
border-color:  #e1e1e1;
background-color:  #ffffff;
width: 37px;
height: 24px;
}
.pager .ago{
  background-image: url('images/ago.png'); 
  background-repeat: no-repeat;
  width: 12px;
height: 11px;
    margin: 6px 12px;
 
}
.pager .button-pager:hover .ago{
  background-image: url('images/ago-hover.png'); 
  background-repeat: no-repeat;
  width: 12px;
height: 11px;

}
.pager .forward{
  background-image: url('images/forward.png'); 
  background-repeat: no-repeat;
  width: 12px;
height: 11px;
    margin: 6px 12px;
  
}
.pager .button-pager:hover .forward{
  background-image: url('images/forward-hover.png'); 
  background-repeat: no-repeat;
  width: 12px;
height: 11px;

}
.pager a{
  float: left;
  color:  #202020;
font-size: 14px;
font-weight: 400;
letter-spacing: -0.28px;
line-height: 24px;
text-decoration: none;

}
.pager a.pag{
    padding: 0 12px
}
.pager a.pag:hover{
    color: #fe7900;
}
.pager b{
  float: left;
  border-radius: 3px;
background-color:  #fe7900;
width: 25px;
height: 24px;
color:  #ffffff;
font-size: 14px;
font-weight: 400;
letter-spacing: -0.28px;
line-height: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: #fff;
}
.frame.responsive-block.subdirectory-seo h3{
  color:  #fe7900;
font-size: 19px;
font-weight: 700;
line-height: 29px;
}
.frame-card:after{
clear:both;
content: '';
display:block;
}
.frame-card{
  width: 100%;
  display: block;
      margin-bottom: 20px;
}
.frame-card:after{
clear: both;
content: '';
display: block;
}
.frame-card .photo-card{
  float: left;
width: 35%;
display: block;

}
.frame-card .photo-tovar{
      border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    background-color: #ffffff;
    width: 268px;
    height: 259px;
    border-radius: 4px;
    margin-right: 5px;
}
.frame-card .description-card{
width: 64%;
float: left;
margin-left: 5px;
}

.frame-card .preview{
      width: 270px;
      height: 58px;
      position: relative;
          overflow: hidden;

}
.frame-card .preview ul{
  position: relative;
      margin: 3px 23px;
      clear: both;
      content: '';
      display: block;
}
.frame-card .preview ul:after{
 
      clear: both;
      content: '';
      display: block;
}
.frame-card .preview li{
  width: 20%;
  float:left;
  width: 46px !important;
  margin: 4px;
  padding: 0 !important;
  border-style: solid;
border-width: 1px;
border-color:  #e1e1e1;
background-color:  #ffffff;
}
.frame-card .preview img{
  width: 46px;
height: 46px;
}

a.jcarousel-control-prev.left-arrow-min{
  float: left;
  background-image: url('images/left-arrow-min.png');
  background-repeat: no-repeat;
  width: 9px;
height: 24px;
margin-right: 5px;
position: absolute;
    top: 16px;;
    left: 4px;
content: '';

}
a.jcarousel-control-prev.left-arrow-min:hover{
  background-image: url('images/left-arrow-min.png');
}
a.jcarousel-control-next.right-arrow-min{
  float: left;
    background-image: url('images/right-arrow-min.png');
    background-repeat: no-repeat;
    width: 9px;
height: 24px;
    margin-right: 5px;
    position: absolute;
    top: 16px;;
    left: auto;
    right: 0;
    content: '';
}
a.jcarousel-control-next.right-arrow-min:hover{
  background-image: url('images/right-arrow-min.png');

}
.head-card-mob{
  display: none !important;
}

.head-card{
  border-radius: 2px;
background-color:  #f0f0f0;
height: 27px;
width: 100%;
display:block;
}
.kod-tov{
  color:  #202020;
font-size: 15px;
font-weight: 400;
letter-spacing: 0.375px;
    padding: 5px 15px;
    float: left;
}
.reiting{
float: right;
height: 16px;
    padding-top: 2px;
}
.reiting ul{
}
.reiting ul li{
  background-image: url('images/reiting.png');
  background-repeat: no-repeat;
  width: 17px;
height: 16px;
float: right;
}
.reiting-description{
float: right;
color:  #202020;
font-size: 15px;
font-weight: 400;
letter-spacing: 0.375px;
    padding: 5px 15px;
}
.box-cena{
  width: 100%;
     padding: 14px 0 0 14px;
}
.blok-cena{
float: left;
}
.box-buttom-cena{
  float: left;
  width: 50%;
      text-align: center;
      padding-top: 26px;
}
.buttom-cena{
   border-radius: 4px;
    /* background-color: #fe7900; */
    background-color: #474747;  
    width: 137px;
    height: 34px;
    padding: 8px 0 0 14px;
    display: inline-block;
    /* display: inline; */
    vertical-align: top;
    float: none;

}
.buttom-cena:hover{
  
    background-color: #202020;
    

}
.buttom-cena p{
    color: #ffffff;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: 0.095px;
    text-align: center;
    padding: 3px 52px;
}
.buttom-cena .card-icon{
    background-image: url('images/icon-card.png');
    background-repeat: no-repeat;
    display: block;
    height: 27px;
}
.art-chek{
float: left;
    padding-top: 36px;
 
}
.art-chek:after{
clear:both;
content: '';
display:block;
}
.art-chek .check{
  float: left;
  float: left;
    padding-right: 10px;
    cursor: default;
}
.art-chek .check p{
  float: left;
      padding-left: 30px;
          width: 76px;
              padding-top: 3px;
}
.old-cena{
color:  #737373;
font-size: 19px;
font-weight: 400;
letter-spacing: 0.475px;
text-align: left;
    padding-bottom: 10px;
    text-decoration: line-through;
}
.current-cena{
color:  #fe7900;
font-size: 28px;
font-weight: 400;
letter-spacing: 0.7px;
text-align: left;
    padding-bottom: 8px;
}
.dollar-cena{
  color:  #202020;
font-size: 19px;
font-weight: 400;
letter-spacing: 0.475px;
text-align: left;
    padding-bottom: 8px;
}
.check-y p{
color:  #202020;
font-size: 15px;
font-weight: 400;
letter-spacing: 0.375px;
}
.box-cena:after{
clear:both;
content: '';
display:block;
}
.servise-box{
  width: 100%;
  border-radius: 3px;
  height: 78px;
  background-color: #f0f0f0;
}
.servise-box li{
   width: 32%;
   float: left;
       padding-top: 10px;
}
.servise-box li:after, .servise-box p:after{
clear:both;
content: '';
display:block;
}
.servise-box .vertical-line{
  margin-top: 3px;
}
.servise-box p{
  font-family: Arial;
color:  #202020;
font-size: 13px;
font-weight: 400;
line-height: 14px;
text-align: center;
     padding: 3px 19px 0 19px;
}
.servise-box li:hover p{
color:  #fe7900;
}
.dostavka:before{
  content: url('images/dostavka.png');
      margin-left: 39%;

}
.stoimost:before{
content: url('images/stoimost.png');
   margin-left: 46%;


}
.raspechatat:before{
  content: url('images/raspechatat.png');
      margin-left: 45%;

}
.common-box{
  width: 100%;
  height: 27px;
      margin: 18px 0;
}
.box-video{
  width: 40%;
  float: left;
   background-image: url('images/video.png');
  background-repeat: no-repeat;
padding-left: 28px;
    height: 25px;
}
.common-box .box-video p{
 color:  #fe7900;
font-size: 14px;
font-weight: 400;
text-align: left;
padding-top: 2px;
}
.social-box{
  width: 70%;
  float: left;
}

.frame-card-title{
  border-radius: 4px;
background-color:  #f0f0f0;
width: 100%;
height: 31px;
    margin-bottom: 6px;
}
.orang-left{
  border-radius: 4px 0px 0px 4px;
background-color:  #fe7900;
width: 8px;
height: 31px;
float: left;
}
.frame-card-title h2{
      color: #575757;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.09px;
    line-height: 17px;
    text-align: left;
    float: left;
    padding-top: 9px;
    padding-bottom: 2px;

}
.frame-card-title .orang-title{
  color:  #fe7900;
font-size: 18px;
font-weight: 400;
letter-spacing: 0.09px;
line-height: 14px;
text-align: left;
float: left;
    padding: 9px 6px 0 6px;
}
.table-characteristics table {
  border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    margin: 0 !important;
    border-radius: 4px;
}
.table-characteristics table tr:nth-child(odd){
 background-color:  #f6f6f6;
}
.table-characteristics table tr{
 height: 35px;
}
.table-characteristics table td{
   
  width: 50%;
  color:  #e1e1e1;
font-size: 15px;
font-weight: 400;
letter-spacing: 0.075px;
line-height: 14px;
text-align: left;
padding: 10px 0 0 10px;
border-bottom: 1px solid;
border-top: 0px solid;
border-left: 0px solid;
border-right: 0px solid;
}
.table-characteristics td.first-tab{
  border-bottom: 1px solid;
border-top: 0px solid;
border-left: 0px solid;
border-right: 1px solid;
}
.table-characteristics table td p{
  color:  #202020;
}
.frame-card-marg{
  margin-bottom: 12px;
}
.card-desctiption{
      padding: 18px 23px ;
    color: #202020;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: justify;
}
.video-card{
  width: 100%;
      padding-left: 23px;
}
.fluid-width-video-wrapper{
  padding: 35px 42px 35px 42px;
  float: left;
}
.fluid-width-video-wrapper iframe{
width: 319px;
height: 222px;
}
.reviews{
  width: 100%;

}
 .user-reviews:after{
  clear: both;
  content: '';
  display: block;
}
.user-avatar{
  background-image: url('images/user-avatar.png');
  background-repeat:no-repeat;
  height: 60px;
      width: 7%;
    float: left;
    padding-right: 20px;
}
.admin-avatar{
  background-image: url('images/admin-avatar.png');
  background-repeat:no-repeat;
  height: 60px;
      width: 10%;
    float: left;
    padding-right: 20px;
}
.reviews ul li.user-reviews{
  padding: 0px;
}
.reviews ul{
   padding-top: 20px;

}
.no-bord {
  border: none;
}
.user-text{
  width: 90%;
  float: left;
}
.user-text h4{
  color:  #fe7900;
font-size: 18px;
font-weight: 400;
letter-spacing: 0.09px;
line-height: 20px;
text-align: left;
}
.user-text p{
  color:  #202020;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 21px;
text-align: justify;
}
.admin-text{
  width: 86%;
  float: left;
}
.admin-text h4{
  color:  #fe7900;
font-size: 18px;
font-weight: 400;
letter-spacing: 0.09px;
line-height: 20px;
text-align: left;
}
.admin-text p{
  color:  #202020;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 21px;
text-align: justify;
}
li.admin-reviews{
  background-color: #f6f6f6;
      display: inline-block;
      padding: 9px 3px 5px 3px !important;
      border-radius: 4px;
          margin-left: 70px;
          width: 100%;
              width: 91%;
}
.reviews .button p{
  color:  #ffffff;
font-size: 15px;
font-weight: 400;
letter-spacing: 0.375px;
text-align: center;
padding-top: 5px;

}
.reviews .button{
  width: 143px;
  margin: 0 auto;
}
.news_list{
  text-align: justify;
      
}
.news_header{
  font-size: 15px;
  width: 66%;
line-height: 22px;
text-decoration: underline;
float: left;
    margin-left: 24px;
    padding-bottom: 16px;
    margin-right: 16px;

}
.news-description{
  font-size: 14px;
  width: 66%;
line-height: 22px;
float: left;
    margin-left: 24px;
    margin-right: 16px;

}
.news-images{
  float: left;
  width: 226px;
height: 161px;
    margin-left: 16px;

}
ul.news_list li{
width: 100%;
height: 100%;
border-bottom: 1px dotted #b8b8b8;
    padding: 30px 0 30px 0;
}
ul.news_list li:after{
  clear: both;
  content: '';
  display: block;
}
ul.news_list:after{
  clear: both;
  content: '';
  display: block;
}
.news_more.button{
  float: left;
      margin-left: 24px;
  width: 166px;
}
.box-pager{
  margin: 20px !important;
}
.single-news h3{
font-weight: 400 !important;
}
.single-news-description{
   width: 95% !important;
}
.single-news_list img{
  clear: both;
  float: left;
  margin: 0 20px 20px 0;
}
.single-news_list p{
      margin-bottom: 22px;
}
.single-news-description .text-list{
padding: 0px;
}
li.no-border{
  border: none !important;
}
.news-video{
  text-align: center;
  margin-bottom: 24px;
}
.box-single-news .button{
 width: 166px;
}
.single-news{
  margin-bottom: 14px;
}
.title-page-contact{
margin: 16px 0 16px 0;
text-align: center;
}
.title-page-contact h1{
    font-size: 19px;
font-weight: 400;
line-height: 14px;
}
.contact{
  float: left;
    width: 37.5%;
    padding-left: 16px;
    padding-right: 16px;
}
.map{
      float: right;
    width: 58.5%;
    height: 404px;
}
.map iframe{
  width: 100%;
    height: 100%;
}
.orang-bolth{
  margin: 17px 0 12px 0;
}
.orang-bolth p{
  font-size: 18px;
font-weight: 400;
line-height: 21px;   
}
.contact .text{
  padding: 0;
}
.text .box-contact{
  margin-bottom: 17px;
}
.text .box-contact p{
text-indent: 0 !important;
}
.orang-smol{
  font-size: 11px;
font-weight: 400;
line-height: 14px;

}
.contact-form-list{
  text-align: center;
      margin-top: 42px;
}
.cf-prosba p{
  font-size: 14px;
font-weight: 700;
letter-spacing: -0.14px;
line-height: 21px;
/*float: left;*/
}
.cf-prosba{
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
    text-align: left;
}
.arrow-horisont{
     background-image: url('images/arrow-horisont.png');
    background-repeat: no-repeat;
    width: 125px;
    height: 51px;
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
}
.contact-form-box{
  font-size: 15px;
font-weight: 400;
line-height: 14px;
text-align: left;
}
.formRow{
  float: left;
  width: 47%;
  margin: 20px 0 0 20px;
}
.labelLeft{
  margin: 20px 20px 0 20px;
  float: left;
  width: 100%;
}
.label{
  float: left;
  margin-right: 30px;
      padding-top: 6px;
}
.formRow input[type="text"]{
      float: left !important;
    width: 71% !important;
    border-radius: 3px;
border-style: solid;
border-width: 1px;
border-color:  #e1e1e1;
box-sizing: border-box;
background-color:  #ffffff;
height: 29px;
}
.contact-form-box textarea{
        float: left !important;
    width: 83.3% !important;
    border-radius: 3px;
border-style: solid;
border-width: 1px;
border-color:  #e1e1e1;
box-sizing: border-box;
background-color:  #ffffff;

}
.require{
  margin-right: 20px;
}
.upload_field {
  float: left;
  width: 50%;
}
.control_code{
  float: left;
  width: 50%;
}
.cf:after, .contact-form-box:after, .box-file-control:after{
  clear: both;
  content: '';
  display: block;
}
.formRow-file, .form_note_block, .captcha_img_box{
  float: left;
}
.box-file-control{
  margin: 24px 0 20px 20px;
}
input.fille-button{
  width: 115px;
height: 30px;
cursor: pointer;
}
.ro-right{
      text-align: left;
    width: 62%;
    float: right;
    margin-right: 19%;
    margin-top: 6px;
}
.input-captcha{
      float: left;
    
    margin: 0 0 0 14px;
}
.captcha_label{
      padding: 6px 0 12px 0;
}
.input-captcha input[type="text"]{
  border-radius: 3px;
border-style: solid;
border-width: 1px;
border-color:  #e1e1e1;
box-sizing: border-box;
background-color:  #ffffff;
width: 250px;
height: 29px;
}
.arhiv_butt .button{
  width: 166px;
      margin-right: 16px;
    margin-top: 12px;
}
.arhiv_butt .button p{
      padding-top: 8px;
}
.control_code .form_note_block{
  float: right;
  margin-right: 16px;
}
.to-right{
  text-align: right;
}
.text-waranty p{
      text-indent: 0 !important;
          margin-bottom: 18px;
}
.text-waranty{
  padding: 26px 24px 0 24px !important;
}
.waranty h4{
  color:  #202020;
font-size: 14px;
font-weight: 700;
letter-spacing: 0.35px;
line-height: 21px;
text-align: justify;
padding-left: 24px;
}
.waranty .text-list{
      padding: 0 23px 12px 23px !important;
}
.text-box-grey{
  background-color:  #f0f0f0;
      padding: 16px 24px 0 24px !important; 
    border-top: 1px dotted #b8b8b8;
    border-bottom: 1px dotted #b8b8b8;
    display: inline-block;  
        margin-bottom: 22px;  
}
.text-box-grey p{
  /* margin-bottom: 0 !important;*/
}

.no-pad{
  padding-top: 0px !important
}
h4.top-h4{
      padding: 26px 24px 12px 24px !important;
}
.faq_form{
  float: left;
  width: 67%;
  font-size: 15px;
font-weight: 400;
line-height: 14px;
text-align: left;
    margin: 42px 0 26px 26px;
}
.formRow-faq select, .formRow-faq input{
  border-radius: 3px;
border-style: solid;
border-width: 1px;
border-color:  #e1e1e1;
box-sizing: border-box;
background-color:  #ffffff;
width: 462px;
height: 29px;
float: right;
}
.formRow-faq{
  margin-top: 10px;
}
.formRow-faq:after{
  clear: both;
  content: '';
  display: block;
}
.formRow-faq textarea{
border-radius: 3px;
border-style: solid;
border-width: 1px;
border-color:  #e1e1e1;
box-sizing: border-box;
background-color:  #ffffff;
width: 462px;
height: 149px;
float: right;
    margin-bottom: 10px;
}
.faq-orang{
  float: left;
      margin-left: 120px;
    width: 52%;
}
.button_row{
  float: left;
}
.faq-buttom .button{
  width: 140px;
}
.faq-hint{
  float: left;
      width: 23%;
    margin: 98px 30px;
    position: relative;
}
.faq-hint p{
  font-size: 14px;
font-weight: 700;
letter-spacing: -0.14px;
line-height: 21px;
text-align: left;
}
.arrow-vertikal{
  background-image: url('images/arrow-vertikal.png');
  background-repeat: no-repeat;
  width: 102px;
height: 89px;
position: absolute;
    top: 56px;
    left: 48px;
}
.no-border{
  border: none;
}
.no-border h4{
  color:  #fe7900;
font-size: 18px;
font-weight: 400;
line-height: 14px;
text-align: left;
    margin-left: 26px;
}
.no-border li{
  
/*border-radius: 4px;
background-color:  #f6f6f6;*/
width: 852px;
margin: 10px 0;
min-height: 44px;

}

.no-border li a{    
  color:  #202020;
  vertical-align: middle;
    display: table-cell;
    font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 21px;
text-align: justify;
    height: 43px;
  }
.no-border li.question p.grey-bg:before  {
   content: url('images/arrow-close.png'); 
       margin-right: 20px;
  }
  .no-border  p{
    font-size: 14px;
font-weight: 400;
letter-spacing: 0.35px;
line-height: 21px;
text-align: justify;
  }
.mob-menu-open, .art-nav p, .orang-cont-mob, .b-m, .cart-popup-mob{
  display: none;
}
li.question {
  cursor: pointer;
  padding-left: 0 !important;
  width: 100%;
}
.answer{
  display: none;
}
p.grey-bg {
      border-radius: 4px;
    background-color: #f6f6f6;
        padding: 11px 10px;
}
ul .answer li{
  padding-left: 0 !important;
}
li.question.active p.grey-bg{
background-color:  #ececec;
}
.no-border li.question.active p.grey-bg:before{
  content: url('images/arrow-open.png'); 
  margin-right: 20px;
}
.contact-form .b-m{
  display: none;
}
input.button {
    color: #ffffff;
    font-size: 15px;
    text-align: center;
        border: none;
}
/* Created by Artisteer v4.1.0.60046 */



.responsive body
{
   min-width: 240px;
}
  
.responsive .art-content-layout img,
.responsive .art-content-layout video
{
   max-width: 100%;
   height: auto !important;
}

.responsive.responsive-phone .art-content-layout img 
{
   float: none !important;
   /*margin: 1%;*/
}

.responsive.responsive-phone .art-collage {
   margin: 1% !important;
}
    
.responsive .art-content-layout .art-sidebar0, 
.responsive .art-content-layout .art-sidebar1, 
.responsive .art-content-layout .art-sidebar2 
{
   width: 100% !important;
}
    
.responsive .art-content-layout, 
.responsive .art-content-layout-row, 
.responsive .art-layout-cell 
{
   display: block;
}
    
.responsive .image-caption-wrapper 
{
   width: auto;
}
.responsive .art-vmenublock{
margin: 0 !important;
}
.responsive.responsive-tablet .art-vmenublock,
.responsive.responsive-tablet .art-block
{
   /*margin-left: 1%;
   margin-right: 1%;*/
   width: 100%;
   float: left;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}
.responsive ul.art-vmenu>li>div{
  border-top-left-radius: 0px; 
     border-top-right-radius: 0px; 
}
/*.responsive ul.art-vmenu li ul.active li.active-tab{
      border-radius: 0px;
}*/

.responsive .art-responsive-embed 
{
   position: relative;
   /*padding-bottom: 56.25%;*/
   /* 16:9 */
	height: 0;
  width: 132px;
height: 91px;
}

.responsive .art-responsive-embed iframe,
.responsive .art-responsive-embed object,
.responsive .art-responsive-embed embed
{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.responsive .art-header 
{
   width: auto;
   height: 156px;
   min-height: 100px;
   min-width: 1%;
   
   /*  *
   margin-top: 1%;
   margin-right: 1% !important;
   margin-left: 1% !important;
   /*  */
   margin-top: 0;
   margin-right: 15px !important;
   margin-left:  15px !important;
   
   /*
   background-position: center center !important;
   background-size: cover !important;
   background-repeat: repeat !important;
   */
   
   position: relative;
}
.responsive .art-header:after{
clear:both;
content: '';
display:block;
}

.responsive .art-header .art-headline, 
.responsive .art-header .art-slogan 
{
   display: block !important;
   top: 0 !important;
   left: 0 !important;
   margin: 2% !important;
}
    
.responsive .art-header .art-headline a, 
.responsive .art-header .art-slogan 
{
   white-space: normal !important;
}
  
.responsive .art-header *
{
  
   text-align: center;
   -webkit-transform: none !important;
   -moz-transform: none !important;
   -o-transform: none !important;
   -ms-transform: none !important;
   transform: none !important;
}

.responsive  #art-flash-area,
.responsive .art-shapes>*
{
   display: none;
}
.responsive .top-nav{
   display: none;
}
.responsive #art-header-bg 
{
   background-position: center center !important;
   background-size: cover !important;
   background-repeat: repeat !important;
}
.responsive .art-shapes-responsive{
   display: block;
       position: relative;
}
/* Search and other elements in header element directly */
.responsive .art-header>.art-textblock
{
   position: relative !important;
   display: block !important;
   margin: 1% auto !important;
   width: 75% !important;
   top: auto !important;
   right: auto !important;
   bottom: auto !important;
   left: auto !important;
}

/* For icons like facebook, rss, etc. */
.responsive .art-header>.art-textblock>div 
{
   width: 100%;
}
/* dynamic width nav */
.responsive nav.art-nav,
.responsive .art-nav-inner
{
   width: auto !important;
   position: relative !important;
   top: auto !important;
   left: auto !important;
   right: auto !important;
   bottom: auto !important;
   margin-top: 0;
   margin-bottom: 0;
   min-width: 0;
   text-align: left !important;
}

.responsive nav.art-nav
{
   min-width: 1%;
   margin-right: 1% !important;
   margin-left: 1% !important;
  margin-top: -1px;
           border-radius: 0px;
           height: 41px;
}

.responsive .art-nav 
{
   padding-left: 0;
   padding-right: 0;
}

/* full width hmenu, instead of inline-block */
.responsive .art-nav ul.art-hmenu 
{
   float: none;
   text-align: center;
   display: none;
}

/* elements on different lines */
.responsive .art-nav ul.art-hmenu li,
.responsive .art-hmenu-extra1,
.responsive .art-hmenu-extra2
{
   float: none;
}
  
/* horizontal margins */
.responsive .art-nav ul.art-hmenu>li:first-child, 
.responsive .art-nav ul.art-hmenu>li:last-child, 
.responsive .art-nav ul.art-hmenu>li
{
   margin-left: 0;
   margin-right: 0;
}
 
/* separator */ 
.responsive .art-nav ul.art-hmenu>li:before
{
   display: none;
}

/* vertical distance between items */
.responsive .art-nav ul.art-hmenu a
{
   margin-top: 1px !important;
   margin-bottom: 1px !important;
   height: auto;
   white-space: normal;
}

.responsive .art-nav ul.art-hmenu>li:first-child>a
{
   margin-top: 0 !important;
}

.responsive .art-nav ul.art-hmenu>li:last-child>a
{
   margin-bottom: 0 !important;
}

/* fixes for extended menu */
.responsive .art-nav .ext, 
.responsive .art-nav ul.art-hmenu>li>ul, 
.responsive .art-nav ul.art-hmenu>li>ul>li,
.responsive .art-nav ul.art-hmenu>li>ul>li a
{
   width: auto !important;
}

/* submenu position on hover */
.responsive .art-nav ul.art-hmenu ul
{
   left: auto !important;
   right: auto !important;
   top: auto !important;
   bottom: auto !important;
   display: none !important;
}

.responsive .art-nav ul.art-hmenu li.active>ul
{
   display: block !important;
}
.responsive .art-nav ul.art-hmenu ul li.active>a
{
   font-weight: bold;
}

.responsive .art-nav .art-menu-btn 
{
   display: block;
    margin: 8px 15px;
    position: relative;
    width: 20px;
}

.responsive .art-nav .art-menu-btn span 
{
   background: #fff;
   display: block;
   height: 3px;
   margin: 3px 1px;
   position: relative;
   -moz-transition: background .2s;
   -o-transition: background .2s;
   -webkit-transition: background .2s;
   transition: background .2s;
   width: 24px;
height: 4px;
}

.art-nav .art-menu-btn:hover span
{
   background: #f3f3f3;
}

.responsive .art-nav .art-menu-btn 
{
   display: inline-block;
}

.responsive .art-nav .art-hmenu.visible 
{
   display: block;
}

.responsive .art-nav ul.art-hmenu>li>ul>li:first-child:after 
{
   display: none;
}

.responsive .art-sheet
{
   width: auto !important;
   min-width: 240px !important;
   max-width: none;
   
   /* v1
   margin-right: 1% !important;
   margin-left: 1% !important;
   */
   margin-top: 0 !important;
   
   /*
   margin-right: 15px !important;
   margin-left:  15px !important;
   */
   
   margin-right: 15px ;
   margin-left:  15px ;
   
}

#art-resp {
   display: none;
}

@media all and (max-width: 1209px)
{
    #art-resp, #art-resp-t { display: block; }
    #art-resp-m { display: none; }
}

@media all and (max-width: 480px)
{
    #art-resp, #art-resp-m { display: block; }
    #art-resp-t { display: none; }
}

.responsive .art-content-layout, 
.responsive .art-content-layout-row, 
.responsive .art-layout-cell 
{
   display: block;
}

.responsive .art-layout-cell 
{
   width: auto !important;
   height: auto !important;
   border-right-width: 0 !important;
   border-left-width: 0 !important;
   border-radius: 0 !important;
}

.responsive .art-content-layout:after, 
.responsive .art-content-layout-row:after, 
.responsive .art-layout-cell:after 
{
   content: ".";
   display: block;
   height: 0;
   clear: both;
   visibility: hidden;
}

.responsive .art-post
{
   border-radius: 0;
}

.responsive .art-footer-inner
{
   width: auto;
   padding: 2% !important;
   min-width: 0;
}

.responsive .art-footer
{
   margin-top: 1%;
}

.responsive .responsive-tablet-layout-cell 
{
   width: 50% !important;
   float: left;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}
.responsive .search{
   display: none;
}
.responsive .search-desktop-responsive{
       display: block;
    width: 100%;
    height: 37px
}
.responsive .art-search-responsive{
        width: 99.7% !important;
    border-style: solid;
    border-width: 1px;
    border-color: #202020;
    height: 35px;
    /* float: left; */
    position: relative;
}
.responsive form.art-search input[type="text"]
{
   margin: 0 auto;
    background-color: #ffffff;
    height: 27px;
    width: 82%;
    border-radius: 0;
    padding-left: 12px;
    padding-right: 20px;
    color: #000;
    float: left;
    font-size: 15px;
    font-weight: 400;
    display: block;
 }
 .responsive form.art-search input[type="submit"]{
    background-color: #202020;
    border-radius: 0;
    width: 40px;
    height: 100%;
    padding: 0;
    cursor: pointer;
    z-index: 999;
    float: left;
    position: inherit;
         }
.responsive .phone-responsive{
    margin: 0;
    border-radius: 0;
    background-color: #000;
    display: block;
    width: 40px;
    height: 36px;
    left: auto;
    right: 0px;
    position: absolute;
}
.responsive .phone-responsive-icon{
       background-image: url('images/phone-responsive.png');
    background-repeat: no-repeat;
    width: 18px;
    height: 24px;
    margin: 0 auto;
    margin-top: 6px;
}
.responsive .cart-detail-responsive{
           display: block;
    width: 100%;
        position: absolute;
            top: 118px;

}
.responsive .quantity-cart, .responsive .sum-cart{
     font-size: 15px;
    font-weight: 400;
    line-height: 7px;
    color: #000;
    float: right;
    padding: 12px 5px 0 0;

}

.responsive .card-responsive{
      display: block;
    background-color: #fe7900;
    width: 41px;
    height: 37px;
    float: right;

}
.responsive .icon-card-responsive{
   display: block;
   background-image: url('images/icon-card-responsive.png');
   background-repeat: no-repeat;
   width: 22px;
height: 21px;
margin: 0 auto;
    margin-top: 7px;
}
.responsive .cart-detail-responsive:after{
clear:both;
content: '';
display:block;
}
.responsive .art-block{
   display: none;
}   
.responsive .responsive-block{
   display: none;
}
.responsive .art-sheet{
   position: relative;
 
}
.responsive .art-sheet:after, .art-content-layout:after, .art-layout-wrapper:after{
   clear:both;
content: '';
display:block;
}

.responsive .art-sidebar1{
   /*display: block;
   position: absolute;
       top: 320px;*/
}

.responsive .popular-tovar .frame-title{
        text-align: left;
 /*   position: absolute;*/
    width: 100%;
    height: 41px;
    z-index: 0;
    background-color: #f0f0f0;
}
.responsive .frame h3{
       font-family: Arial;
    padding: 8px 0 0 14px;
    margin-top: 0;
    display: inline-block;
    background-color: inherit;
    color: #202020;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    line-height: 18px;
}
.responsive a.jcarousel-control-prev{
      background-image: url('images/left-arrow-respo.png');
    background-repeat: no-repeat;
    position: absolute;
    top: 4px !important;
    left: auto;
    right: 20px;
    
}
.responsive a.jcarousel-control-next{
         background-image: url('images/right-arrow-respo.png');
    background-repeat: no-repeat;
    position: absolute;
    top: 4px !important;
    left: auto;
    right: 0px;
    
}
.responsive a.jcarousel-control-prev:hover{
      background-image: url('images/left-arrow-respo.png');
}
.responsive a.jcarousel-control-next:hover{
         background-image: url('images/right-arrow-respo.png');
}

.responsive .card{
    padding: 32px 0px 0 0px;  
}
.responsive .card-art ul{
      margin-top: 38px;
          text-align: center;
}
.responsive .card-art ul li{
      padding: 0px;
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
}
.responsive .card-art ul li:after{
  background-image: none;
}
.responsive .frame{
  border: 0;
  border-radius: 0 !important;
}
.responsive .popular-tovar{
  position: relative;
}
.responsive .art-content-layout .art-content{
     margin: 8px 0 0 0;
         width: 100%;
}
.responsive .card:hover{
    border-radius: 0px;
    border: 0px solid #e1e1e1;
    background-color: transparent;
}
.responsive .art-content{
   /*display: inline-block;
   position: absolute;
   top: 0;*/
       width: 100% !important;
}
.responsive .card-art.jcarousel{
  width: 100%;
  height: 340px;

}
.responsive .cena {
    text-align: center;
}
.responsive .frame .button {
    float: none;
        margin-left: 32px;
}
.responsive .check-y{
   display: none;
}
.responsive .check-n{
   display: none;
}
.responsive .read-more{
   display: none;
}
.responsive .art-post{
  padding: 0 !important;
}
.responsive .home .menu-mob{
  display: block;
}
.responsive .home .menu-desktop{
  display: none;
}
.responsive .menu-mob{
  display: none;
}
.responsive .active-menu-js{
  font-family: ArialNarrow;
color:  #ffffff;
font-size: 20px;/* Приближение из-за подстановки шрифтов */
font-weight: 400;
text-align: right;
}
.responsive .mob-menu-open{
  display: block;
  float: right;
  margin-left: 25px;

}

.responsive .catalog .table-goods li{
      width: 150px !important;
}
.responsive .next-section h3{
  color: #ffffff;
  margin: 0;
}
.responsive .next-section ul{
  margin: 0 0 17px 16px;
}

/*footer*/
.responsive .footer-content.social{
  display: none;
}
.responsive .footer-vertical-line{
  display: none;
}
.responsive .footer-content.delivery{
  display: none;
}
.responsive .footer-content{
float: none;
    width: 100%;
    padding: 0;
}
.responsive .footer-content.info ul{
float: none;
    width: 100%;
}
.responsive .footer-content.contacts{
  background-color: #313131;
}
.responsive .footer-content.contacts ul li.phone-none{
  display: none;
}
.responsive .footer-content.contacts ul li{
  text-align: center;
}
.responsive .footer-content ul {
  padding-bottom: 20px;
}
.responsive .footer-content.info ul li{
text-align: center;
}
.responsive .art-footer-inner h3{
  text-align: center;
      padding: 20px 0 14px 0 !important;
}
.responsive .art-footer-inner{
  padding: 0 !important;
}

.responsive .contacts ul li.footer-grafic:before{
  content:'';
}
.responsive .contacts ul li.footer-phone:before{
  content:'';
}
/*footer*/
.responsive ul.art-vmenu-js ul.active {
    display: none;
}
.responsive .text{
  font-size: 11px !important;
  font-size: 14px !important;
  padding: 2px 6px 0px 6px;
}
.responsive .text{
	content: '';
	clear: both;
	display: block;
}
.responsive .sub-text{
  border-radius: 4px !important;
border-style: solid;
border-width: 1px;
border-color:  #fe7900;
    margin: 0;
}
.responsive .title-page h1{
  font-size: 18px;
}
.responsive .sub-text ul.sub-phone li.sub-number{
  font-size: 11px;
      padding: 0px 12px;
          margin: 0px 6px;
}
.responsive .sub-text ul.sub-phone li.sab-kievstar:before{
  content: url('images/kievstar-black-min.png');
      margin-top: 2px;
}
.responsive .sub-text ul.sub-phone li.sab-mtc:before{
  content: url('images/mtc-black-min.png');
}
.responsive .sorting{
  display: none;
}
.responsive hr{
      border: none;
    background-color: #e1e1e1;
    color: red;
    height: 1px;
        display: block;
    -webkit-margin-before: 0.5em;
    -webkit-margin-after: 0.5em;
    -webkit-margin-start: auto;
    -webkit-margin-end: auto;
    
    border-width: 1px;
}
.responsive .subdirectory{
      margin-bottom: 0;
      padding-bottom: 0;
}
.responsive .card{
  height: 256px;
}
.responsive .pager{
      margin-top: 16px;
    margin-bottom: 12px;
}
.responsive .subdirectory .card-art ul{
  margin-top: 0 !important;
}
.responsive .top_links li a{
  font-size: 13px;
}
.responsive .head-card-mob{
  display: block !important;
}
.responsive .head-card-desktop{
  display: none;
}
.responsive .reiting-description{
  display: none;
}
.responsive .photo-card{
  float: none;
  display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    margin-top: 16px;
    width: 73%;
        text-align: center;

}
.responsive .description-card{
  float: none;
  display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    margin-top: 16px;
    width: 100%;

}
.responsive .frame-card{
  text-align: center;
}
.responsive .photo-tovar{
  width: 198px;
  height: 261px;
      float: none;
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
}
.responsive .photo-tovar img{
      margin-top: 20px;
}
.responsive .preview{
       width: 79px;
    height: 262px;
    float: none;
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
}
.responsive .preview ul{
  margin: 18px 0;
    width: 80px;
    height: 288px;
    overflow: hidden;
}
.responsive .preview li{
          width: 72px !important;
    height: 62px;
    margin: 4px;
    padding: 0 !important;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    background-color: #ffffff;
    float: left;
}
.responsive .preview a.jcarousel-control-prev{
      background-image: url('images/arrow-top.jpg');
    background-repeat: no-repeat;
    position: absolute;
       top: 0px !important;
    left: 4px;
    width: 80px;
    height: 20px;
    background-color: #fff;
  }
.responsive .preview a.jcarousel-control-next {
      background-image: url('images/arrow-buttom.jpg');
    background-repeat: no-repeat;
    position: absolute;
        top: 236px !important;
    left: 4px;
        width: 80px;
    height: 28px;
    background-color: #fff;
}

.responsive .preview ul li img{
      margin-top: 8px;
}
.responsive .box-buttom-cena{
  width: 151px;
  float: right;
      margin-right: 20px;
      display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;

}
.responsive .blok-cena{
   display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
}
.responsive .box-cena{
  text-align: center;
}

.responsive li.raspechatat, .responsive .too ,.responsive .orang-left, .responsive .vopros-otziv{
  display: none;
}
.responsive .servise-box li{
  width: 49%;
}
.responsive .stoimost:before, .responsive .dostavka:before{
  margin-left: 0% !important;
}
.responsive .orang-title {
  font-size: 16px;
}
.responsive .frame-card-title{
  background: transparent;
}
.responsive .frame-card-title h2{
  padding-left: 6px;
  font-size: 16px;
      padding-bottom: 14px;
}
.responsive .frame-card-marg{
  border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
}
.responsive .table-characteristics td.first-tab{

}
.responsive .table-characteristics table td p{
  font-size: 13px;
}
.responsive .card-desctiption{
  font-size: 13px;
  padding: 10px 8px;
}
.responsive .orangy{
  color: #fe7900;
}
.responsive .video-card{
text-align: center;
}
.responsive .fluid-width-video-wrapper{
      display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
    padding: 14px 12px 14px 12px;
}
.responsive .title-page-contact h1{
  color:  #fe7900;
font-size: 18px;
font-weight: 400;
line-height: 21px;
text-transform: uppercase;
}
.responsive .contact{
  width: 100%;
}
.responsive .contacts-box .orang-bolth p{
  font-size: 16px;
      display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
}
.responsive .box-contact p{
  font-size: 12px;
      float: left;
    margin-right: 10px;
        display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
}
.responsive .box-contact{
  text-align: left;
}
.responsive .orang-contact{
  text-align: left;
      float: left;
}
.responsive .box-contact-mob{
	float: left;
	width: 31%;
	margin-right: 7px;
}
.responsive .box-contact-mob p{
	width: 100%;
}
.responsive .mob-no-flo{
	
	width: 62%;
}
.responsive .mob-no-flo p{
	width: 50%;
}
.responsive .orang-cont-mob, .b-m{
	display: block;
}
.responsive .orang-cont-desc{
	display: none;
}
.responsive .contact .text{
	padding-left: 0 !important;
}
.responsive .contacts-box .art-responsive-embed{
	height: 180px !important;
	width: 100% !important;
}
.responsive .map{
	    float: left;
    width: 100%;
    height: 188px;
}
.responsive .contacts-box{
	margin-bottom: 0;
}
.responsive .cf-mob .frame-title h3{
	color:  #fe7900;
font-size: 16px;
font-weight: 400;
line-height: 19px;
padding-left: 0;
}
.responsive .contact-form-list{
	        margin-top: 32px;
}
.responsive .formRow {
	    width: 100%;
    margin: 16px 0 0 0px;
    float: none;
        text-align: center;
}
.responsive .formRow span.label{
	float: none;
	margin: 0;
}
.responsive .formRow input[type="text"]{
	    float: none !important;
    width: 100% !important;
    margin-top: 10px;
}
.responsive .labelLeft{
	margin: 20px 0 0 0;
}

.responsive span.label.require{
	width: 100%;
}
.responsive .contact-form-box textarea{
	    width: 100% !important;
}
.responsive .box-file-control {
    margin: 10px 0 0 0;
}

.responsive .formRow-file span.label{
	margin-bottom: 9px;
    padding-top: 0;
}
.responsive .ro-right{
	float: none;
}
.responsive .input-captcha input[type="text"]{
	width: 148px;
}
.responsive .arhiv_butt {
    text-align: center;
}
.responsive .arhiv_butt .button {
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
    float: none;
        margin-top: 14px;
}
.responsive .arhiv_butt .button p{
	font-size: 13px;
}
.responsive .cf-mob{
	margin-bottom: 10px;

}
.responsive .cart-popup-mob{
	display: block;
}
.responsive .cart-popup-mob .cart-title{
	font-size: 18px;
font-weight: 400;
}
.responsive .cart-popup-mob .cart-body{
	padding: 6px;
}
.responsive .cart-popup-mob .cl2{
	width: 100%;
	padding: 0;
}
.responsive .cart-popup-mob .cart_item_name{
	    margin: 6px 0 14px 0;
	    text-transform: uppercase;
}
.responsive .cart-popup-mob .cart_item_name a{
	font-size: 15px;
font-weight: 400;
line-height: 16px;
}

.responsive .cart-popup-mob .cart_delete {
    float: left;
        margin-top: 36px;
}
.responsive .cart-popup-mob .first_col {
    width: 83px;
    height: 80px;
    margin: 0 10px 0 10px;
}
.responsive .cart-popup-mob .cart_item_data {
    width: 60%;
    float: left;
}
.responsive .cart-popup-mob .cart_item_data .current-cena {
    font-size: 17px;
        float: left !important;
}
.responsive .cart-popup-mob .old-cena.mar-r {
    font-size: 14px;
    padding-top: 6px;
}
.responsive .cart-popup-mob .mar-r {
        margin-right: 8px;
    padding-bottom: 0px;
    padding-top: 4px;
}
.responsive .cart-popup-mob .cart-body ul li {

    height: 144px;
}

.responsive .cart-popup-mob .cart_bottom_title{
	width: 52px;
	    font-size: 16px;
}

.responsive .cart-popup-mob .cart_bottom_count {
    font-size: 15px;
        width: 75px;
}
.responsive .cart-popup-mob .itog-right{
	font-size: 16px;
	width: 138px;
}

.responsive .cart-popup-mob .cart_bottom_price{
	font-size: 17px;
}
.responsive .cart-popup-mob .box-itog {
    padding-left: 0px;
}
.responsive .cart-popup-mob .ui-cart-button .button p{
	font-size: 13px;
}
.responsive .cart-popup-mob .ui-cart-button .button{
	width: 135px;
}
.responsive .cart-popup-desck{
	display: none !important;
}

.responsive .question-answer h3{
	color: #fe7900;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-transform: uppercase;
}
.responsive .faq_form{
	width: 100%;
    float: none;
    margin: 30px 0px 10px;
}
.responsive span.label.require {
    text-align: center;
        padding-bottom: 10px;
}
.responsive .formRow-faq select, .formRow-faq input {
    width: 100%;
    float: none; 
}
.responsive .formRow-faq textarea {
    
    width: 100%;
    height: 70px;
    float: none;
   
}
.responsive .faq-hint{
	display: none;
}
.responsive .faq-buttom{
	text-align: center;
}
.responsive .faq-orang {
    float: none;
    margin-left: 0;
    width: 100%;
}
.responsive .faq-buttom .button p{
	font-size: 13px;
}
.responsive .faq-buttom .button {
    width: 166px;
    margin-left: 0px;
        float: none;
    display: inline-block;
    _display: inline;
    /* display: inline; */
    vertical-align: top;
        margin-top: 10px;
}
.responsive .question-answer{
	/* margin-bottom: 0px !important; */
}
.responsive .quest h4{
	margin-left: 0;
}
.responsive .contact-form .b-m{
  display: block;
}

/* 
.responsive 
.responsive
.responsive
.responsive
*/

